.logo-card {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;

    &__media {
        flex-shrink: 0;
    }

    &__content {
        padding: $bufferSpaceS;
    }

    &__buttons {
        margin-top: $bufferSpaceS;
    }


    h3.logo-card__title {
        margin-top: 0;
    }
}