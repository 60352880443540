@use "sass:math";

.table-styled {
    width: 100%;
    border-top: $strokeWidth solid $cBorder;
    font-size: rem($typeDefault);

    td {
        position: relative;
        line-height: 1.4;
        vertical-align: top;
    }

    caption {
        font-weight: bold;
        margin: $bufferSpaceS 0;
    }

    &--key-stats {
        @include respond-to-max($vpTable) {
            border-collapse: separate;
            table,
            thead,
            tbody,
            th,
            td,
            tr {
                display: block;
            }

            tr {
                margin-bottom: math.div($bufferSpaceS, 2);
                border-top: $strokeWidth solid $cBorder;
                overflow: hidden;
                &:first-child {
                    border-top: none;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            td {
                position: relative;
                background: #fff;
                border-bottom: $strokeWidth solid $cBorder;
                border-right: $strokeWidth solid $cBorder;
                border-left: $strokeWidth solid $cBorder;
                padding-left: 0;
                vertical-align: top;
                padding: math.div($bufferSpaceS, 3) math.div($bufferSpaceS, 2);
            }
        }

        @include respond-to($vpTable) {
            border: $strokeWidth solid $cBorder;
            th,
            td {
                border-right: $strokeWidth solid $cBorder;
                &:last-child {
                    border-right: none;
                }
            }
            thead {
                th {
                    padding: math.div($bufferSpaceS, 2);
                    background-color: $cCanvasDark;
                    border-color: $cBorder;
                    color: $cHeading;
                }
            }
            tbody {
                tr {
                    background: #fff;
                    border-bottom: $strokeWidth solid $cBorder;
                    &:nth-child(even) {
                        background-color: lighten($cBorder, 6%);
                    }
                }
                td {
                    padding: math.div($bufferSpaceS, 3) math.div($bufferSpaceS, 2);
                }
            }
        }
    }

    &--block {
        border: 0;

        th,
        td {
            border-right: $strokeWidth solid $cBorder;
            &:last-child {
                border-right: none;
            }
        }

        th {
            padding: math.div($bufferSpaceS, 2);
            background-color: $cCanvasDark;
            border-color: $cBorder;
            color: $cHeading;
        }

        tbody {
            tr {
                background: #fff;
                border-bottom: $strokeWidth solid $cBorder;

                &:nth-child(even) {
                    background-color: lighten($cBorder, 6%);
                }
            }

            td {
                padding: math.div($bufferSpaceS, 3) math.div($bufferSpaceS, 2);
            }
        }
    }
}

.table-block-overflow {
    overflow: auto;
}