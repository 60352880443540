
// Icons pop
@-webkit-keyframes hvr-icon-pop {
  50% {
    transform: scale(1.25) translateY(-50%);
  }
}

@keyframes hvr-icon-pop {
  50% {
    transform: scale(1.25) translateY(-50%);
  }
}

@keyframes anim-menu-w {
  0% {
    width: 30px;
  }
  50% {
    width: 40px;
  }
  100% {
    width: 30px;
  }
}


@keyframes anim-menu-s {
  0% {
    width: 30px;
  }
  50% {
    width: 20px;
  }
  100% {
    width: 30px;
  }
}