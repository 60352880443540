.banner {
    position: relative;
    z-index: 3;
    background-color: $cCerise;
    padding: $bufferSpaceS 0;
    &--sticky {
    	position: fixed;
    	bottom:0;
    	left:0;
    	width: 100%;
    	z-index: 999;
    }
}

.banner__copy {
    color: $cCanvas;
    text-align: center;
    font-size: rem($typeS);
    padding-right: $bufferSpaceM;
    p {
    	margin-top: $bufferSpaceS*.5;
    	&:first-child {
    		margin-top: 0;
    	}
    }
    a {
        color:$cCanvas;
        text-decoration: underline;
        border-bottom: none;
        margin-left: $bufferSpaceS;
    }
}

.banner__trigger {
    display: none;
    html.js & {
        position: absolute;
        display: block;
        top: 0;
        right: $bufferSpaceS;
        outline: none;
        cursor: pointer;
        svg {
            width: 24px;
            height: 24px;
            fill: $cCanvas;
        }
    }
}