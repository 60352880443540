.cookies {
	position: relative;
	z-index: 3;
	background-color: $cCerise;
	padding: $bufferSpaceS 0;
	&--sticky {
		position: fixed;
		bottom:0;
		left:0;
		width: 100%;
		z-index: 999;
	}
	p {
		margin-top: $bufferSpaceS*.5;
		&:first-child {
			margin-top: 0;
		}
	}
	a {
		text-decoration: none;
		cursor: pointer;
		color: $cCanvas;
		border-bottom: 2px solid $cCanvas;
		@include link-active-styles {
			border-bottom: 2px solid $cAction;
		}
	}

	html.no-js & {
		display: none;
	}
}

.cookies__wrap {
	@include respond-to($vpC) {
		@supports (display: grid) {
			display: flex;
		}
	}
}

.cookies__copy {
	color: $cCanvas;
	text-align: left;
	font-size: rem($typeS);
	padding-right: $bufferSpaceM;
	@include respond-to($vpC) {
		width: 65%;
		float: left;
		@supports (display: grid) {
			width: auto;
			flex-grow: 1;
		}
	}
}

.cookies__actions {
	font-size: rem($typeS);
	padding-top: $bufferSpaceS;
	button {
		display: inline-block;
		margin-right: $bufferSpaceS;
	}
	&--secondary {
		text-decoration: none;
		cursor: pointer;
		color: $cCanvas;
		border-bottom: 2px solid $cCanvas;
		@include link-active-styles {
			border-bottom: 2px solid $cAction;
		}
	}
	@include respond-to-max($vpC) {
		.button {
			margin-bottom: 1rem;
		}
	}
	@include respond-to($vpC) {
		width: 35%;
		float: right;
		padding-top: 0;
		button {
			float: right;
			margin-right: 0;
			margin-left: $bufferSpaceS;
		}
		@supports (display: grid) {
			width: auto;
			flex-grow: 1;
		}
	}
}
