// Flickity
// https://unpkg.com/flickity@2.2.2/dist/flickity.css

/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
	position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
	overflow: hidden;
	position: relative;
	height: 100%;
}

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: -webkit-grabbing;
	cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
	background: white;
	cursor: pointer;
}

.flickity-button:focus {
	outline: none;
	box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
	opacity: 0.6;
}

.flickity-button:disabled {
	opacity: .5;
	cursor: auto;
	pointer-events: none;
}

.flickity-button-icon {
	fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous { left: 10px; }
.flickity-prev-next-button.next { right: 10px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
	left: auto;
	right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
	right: auto;
	left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
	position: absolute;
	left: 20%;
	top: 20%;
	width: 60%;
	height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -15px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 8px;
	background: #333;
	border-radius: 50%;
	opacity: 0.25;
	cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
	opacity: 1;
}

//Flickity overrides and additions

.carousel {
	padding: $bufferSpaceS 0 $bufferSpaceM;
	&.flickity-enabled {
		// height: 500px;
		// background-color: pink;
	}
}

.typebase--article .carousel.flickity-enabled {
    max-width: 700px;
    margin: 0 auto;
}

.flickity-page-dots {
	position: absolute;
	width: 100%;
	bottom: 10px;
}

.flickity-prev-next-button {
	top: 45%;
}


.flickity-enabled {
	&.grid-3up {
		display: block;
		.carousel__item {
			width: 100%;
			margin-right: 0;
		}
	}
}

.carousel__item {
	.flickity-enabled & {
		@include ratio(4 3);
  		// height: 500px;
		position: relative;
		background-size: cover;
		background-position: center;
	}
	&__caption {
		position: absolute;
		bottom: 10px;
		left: 10px;
		background-color: #fff;
	    border-left: 3px solid $cTurquoise;
	    padding: 5px 10px;
	    margin-right: 10px;
	    font-size: rem($typeS);
	    width: auto;
	    p {
	    	margin-top: 0;
	        font-size: inherit;
	    }
	}
}