.subnavigation {
    display: none;
    max-height: 0px;
    transition: max-height 0s ease-out;
    position: absolute;
    overflow: hidden;
    visibility: hidden;

    @include respond-to($vpMenu) {
        display: block;
    }

    &.active {
        position: relative;
        max-height: 100vh;
        overflow: auto;
        visibility: visible;
        transition: max-height 0.3s ease-out, visibility 0s 0s linear;
    }

    .row {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding-top: 2em;
        padding-bottom: 2em;
        border-top: 3px solid $cActionRespond;
    }

    &__card {
        max-width: 50ch;
        padding-left: 1.5em;
        border-left: 3px solid $cMustard;
        margin-right: 6em;

        &-title {
            font-size: rem($typeM);
            font-family: $fontHeading;
            margin-bottom: 0.3em;

            a {
                color: $cTealMedium;
            }
        }

        &-description {
            font-size: rem($typeDefault);
        }

        &-cta {
            display: block;
            border-bottom: none;
            color: $cTealMedium;
            text-decoration: underline;
            margin-top: 0.5em;
            transition: background-color ease-in-out 0.25s,
                    border ease-in-out 0.25s, padding ease-in-out 0.25s;

            @include link-active-styles {
                border-bottom: none;
            }
        }
    }

    &__nav {
        font-size: rem($typeDefault);
        list-style: none;
        line-height: 1.4;
        width: 100%;
        max-width: 245px;

        @include respond-to($vpMenu) {
            margin-top: 38px;
        }

        > li {
            // @include pie-clearfix;
            border-bottom: 1px solid $cBorder;

            &:last-child {
                border-bottom: none;
            }

            a {
                display: block;
                padding: 0.5em 0;
                transition: background-color ease-in-out 0.25s,
                    border ease-in-out 0.25s, padding ease-in-out 0.25s;
                border-bottom: none;
                color: $cTealMedium;
                text-decoration: underline;
            }

            @include link-active-styles {
                a {
                    background-color: $cCream;
                    border-left: 2px solid $cActionRespond;
                    padding-left: 0.5em;
                    color: $cSlateGrey;
                }
            }
        }

        .navigation-primary__item & {
            @include respond-to($vpMenu) {
                display: none;
            }

            padding-left: 20px;
            max-height: 0px;
            transition: max-height 0s ease-out;
            position: absolute;
            overflow: hidden;
            visibility: hidden;
            max-width: unset;

            &.active {
                position: relative;
                max-height: 100vh;
                overflow: auto;
                visibility: visible;
                transition: max-height 0.3s ease-out, visibility 0s 0s linear;
            }
        }
    }
}
