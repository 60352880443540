// ------------------------------------------------------------
// Navigation
// ------------------------------------------------------------

.navigation-primary {
    display: block;
    background-color: $cCanvas;
	@include respond-to-max($vpMenu) {
		overflow: hidden;
    }
    @include respond-to($vpMenu) {
        margin-top: 2em;
        float: right;
    }
    @include respond-to($vpMenu + 120px) {
        margin-top: 5.7em;

    }
    // French
    @include respond-to($vpMenu + 120px) {
        &:lang(fr) {
            margin-top: 2em;
        }
    }
    @include respond-to($vpMenu + 406px) {
        &:lang(fr) {
            margin-top: 5.7em;
        }
    }
    // Spanish
    @include respond-to($vpMenu + 120px) {
        &:lang(es) {
            margin-top: 2em;
        }
    }
    @include respond-to($vpMenu + 184px) {
        &:lang(es) {
            margin-top: 5.7em;
        }
    }
}

.navigation-primary-toggle {
    position: absolute;
    z-index: 100;
    top: 1em;
	right: $bufferSpaceS*1.5;
	display: block;
    color: $cTealMedium;
    cursor: pointer;
    padding: 20px 0;
    outline: none;
    span {
        transition: all, ease-in-out, 0.3s;
        opacity: 1;
        text-indent: -9999em;
        height: 2px;
        width: 40px;
        background-color: $cTealMedium;
        border-radius: 5px;
        display: block;
    }
	&:before, &:after {
		content: "";
		position: absolute;
		height: 2px;
		width: 30px;
        background-color: $cTealMedium;
        transition: all, ease-in-out, 0.3s;
        border-radius: 5px;
	}
	&:before {
		top: 9px;
		left: 0;
	}
	&:after {
		bottom: 9px;
        left: 0;
        width: 25px;
    }
    @include respond-to($vpB) {
		top: 1.5em;
	}
	@include respond-to($vpMenu) {
		display: none;
	}
}

.navigation-primary-toggle--open {
	span {
        opacity: 0;
    }
    &:before {
        transform: rotate(45deg);
        width: 30px;
        top: 20px;
    }
    &:after {
        transform: rotate(-45deg);
        width: 30px;
        bottom: 20px;
	}
}

.navigation-primary__items {
    position: relative;
    z-index: 20;
	@include respond-to-max($vpMenu) {
        padding: $bufferSpaceS 0;
		html.js & {
            transition: all, ease-in-out, 0.25s;
            max-height: 0;
            padding: 0;
		}
	}
}

.navigation-primary__items--open {
    @include respond-to-max($vpMenu) {
        html.js & {
            max-height: 800px;
            position: absolute;
            width: 100%;
            left: 0;
            background-color: $cCanvas;
            padding: $bufferSpaceS 0;
            overflow: auto;
            .navigation-primary__item {
                opacity: 1;
                transition-delay: 0.25s;
            }
        }
    }
}

.navigation-primary__item {
	@include pie-clearfix;
	display: block;
    font-size: rem($typeM);
    font-family: $fontHeading;
    border-bottom: 1px solid $cBorder;
    opacity: 0;
    html.no-js & {
        opacity: 1;
    }
    padding: 0 $bufferSpaceS;
	a {
		color: $cTealMedium;
        display: block;
        border-bottom: none;
        padding: 0.5em 0;
        display: flex;
        align-items: center;
	}
    &-link__icon {
        height: 12px;
        width: 12px;
        margin-left: 8px;
        transition: transform ease-in-out 0.25s;
        will-change: transform;
        overflow: visible;
    }
	// For search field
	&:last-child {
		border-bottom: none;
	}
	form {
        position: relative;
        input {
            height: 44px;
        }
        button {
            display: inline-block;
            height: 44px;
            position: absolute;
            top: 0;
            right: 0;
        }
	}
	// end of search field
	@include respond-to($vpMenu) {
		display: block;
        float: left;
        margin-left: 1.5em;
        opacity: 1;
        padding: 0;
        &:first-child {
            margin-left: 0;
        }
        border-bottom: none;
		a {
            position: relative;
            border-bottom: none;
            padding: 0;
            &:after {
                content: "";
                position: absolute;
                display: block;
                top: 100%;
                left: -1rem;
                width: calc(100% + 2rem);
                height: 3px;
                background-color: $cActionRespond;
                opacity: 0;
                transition: all, ease-in-out, 0.3s;
            }
			@include link-active-styles {
                color: $cHeading;

                svg {
                    transform: translateY(2px);
                }
                &:after {
                    bottom: 0;
                    opacity: 1;
                }
			}
		}
	}
}

.navigation-primary__item--no-border {
    overflow: hidden;
    border-bottom: none;
    padding: 1em $bufferSpaceS 0 $bufferSpaceS;
    span {
        float: left;
        margin-right: 10px;
    }
}

.navigation-primary__item--ss-only {
	@include respond-to($vpMenu) {
		display: none;
	}
}

.navigation-primary__item--current {
    @include respond-to-max($vpMenu) {
        border-left: 10px solid $cActionRespond;
    }
	a {
		@include respond-to-max($vpMenu) {
            color: $cHeading;
        }
        @include respond-to($vpMenu) {
            color: $cHeading;
            &:after {
                // bottom: 0;
                opacity: 1;
            }
		}
	}
}

.navigation-primary__item-link.active {
    @include respond-to-max($vpMenu) {
        color: $cHeading;
    }
    @include respond-to($vpMenu) {
        color: $cHeading;
        &:after {
            background-color: white;
            // top: 100%;
            opacity: 1;
        }
    }
}


header.header.active {
    .navigation-primary__item-link {
        color: $cTealMedium;

        &:hover,
        &:focus {
            color: $cSlateGrey;
        }
    }
}

// ------------------------------------------------------------
// Secondary navigation
// ------------------------------------------------------------

.navigation-secondary {
    @include pie-clearfix;
    display: none;
    @include respond-to($vpMenu) {
        position: absolute;
        top: 0;
        right: 70px;
        display: flex;
    }
}

.navigation-secondary__items {
    @include pie-clearfix;
    font-size: rem($typeS);
    transform: translateY(5px);
}

.navigation-secondary__item {
    float: left;
    margin-right: 1em;
    a {
        border-bottom: none;
        @include link-active-styles {
            text-decoration: underline;
        }
    }
    &--active {
        a {
            text-decoration: underline;
        }
    }
}

.navigation-languages {
    @include pie-clearfix;
    font-size: rem($typeS);
    @include respond-to-max($vpMenu) {
        float: left;
        .navigation-secondary & {
            display: none;
            float: none;
        }
    }
    &__item {
        float: left;
        margin-left: 0.5em;
        a {
            color: $cHeading;
            font-family: $fontBrand;
            border: 1px solid #fff;
            cursor: pointer;
            display: block;
            padding: 5px 10px;
            border-radius: 3px;
            transition: all, ease-in-out, 0.3s;
            @include link-active-styles {
                background-color: $cCanvasLight;
                border-color: $cCanvasMedium;
                @extend %shadowStraight;
            }
        }
        &--current {
            a {
                background-color: $cCanvasLight;
                border-color: $cCanvasMedium;
                @extend %shadowStraight;
            }
        }
        &:first-child {
            margin-left: 1em;
        }
    }
}