.tile-blocks {
    margin-top: $bufferSpaceM;

    &.grid-block--1 {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;

        .tile {
            margin: 0 auto;
        }

        .tile__media {
            max-height: 200px;
        }
    }
    &.grid-block--2 {
        .tile__media {
            max-height: 200px;
        }
    }

    &.grid-block--3 {
        .tile__media {
            max-height: 150px;
        }
    }

    &.grid-block--4 {
        .tile__media {
            max-height: 120px;
        }
    }
}

.tile {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: white;
    border-radius: 8px;

    &__media {
        width: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__content {
        width: 100%;
        padding: $bufferSpaceS;
        max-width: 100%;
        z-index: 2;

        h3 {
            margin: 0;
        }

        & + .tile__buttons {
            padding-top: 0;
        }
    }

    &__buttons {
        padding: $bufferSpaceS;
    }
}

.typebase ul.card__meta {
    list-style: none;
    padding: 0;
}
