// ------------------------------------------------------------
// Alerts
// ------------------------------------------------------------

.alert {
	padding: 15px;
	padding-left: 40px;
	overflow: hidden;
	display: block;
	position: relative;
	font-size: rem($typeDefault);
	.form & {
		margin-bottom: 2em;
	}
	svg {
		width: 18px;
		height: 18px;
		background-size: 18px 18px;
		display: inline-block;
		position: absolute;
		top: 20px;
		left: 15px;
        margin-top: -1px;
	}
}

.alert--minor {
	background-color: $cCream;
	border-left: 3px solid $cTealMedium;
	font-size: rem($typeS);
	svg {
		width: 15px;
		height: 15px;
	}
	.filter & {
		margin-top: $bufferSpaceS;
	}
}

.alert--notice {
	color: darken($cNotice, 40%);
    background-color: lighten($cNotice, 30%);
    svg {
        fill: darken($cNotice, 40%);
        path {
            fill: darken($cNotice, 40%);
        }
    }
}

.alert--alert {
	color: darken($cAlert, 40%);
    background-color: lighten($cAlert, 25%);
    svg {
        fill: darken($cAlert, 40%);
        path {
            fill: darken($cAlert, 40%);
        }
    }
}


.alert--error {
	@extend .alert--notice;
	background-color: lighten($cError, 55%);
    color: $cError;
    svg {
        fill: $cError;
        path {
            fill: $cError;
        }
    }
}

.alert--success {
	@extend .alert--notice;
	background-color: #E4F5F0;
    color: $cSuccess;
    svg {
        fill: $cSuccess;
        path {
            fill: $cSuccess;
        }
    }
}
