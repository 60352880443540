.stat {
    @extend %pie-clearfix;
    margin-top: $bufferSpaceS;
    @include respond-to($vpC) {
        margin-top: $bufferSpaceM;
    }
    &--trailer {
        @include respond-to($vpC) {
            margin-bottom: $bufferSpaceM;
        }
    }
}

.stat__media {
    width: 60px;
    float: left;
    margin-right: $bufferSpaceS;
    svg {
        position: relative;
        width: 60px;
        height: 60px;
        fill: $cTealMedium;
    }
    img {
        @extend %max-width;
        position: relative;
    }
    @include respond-to($vpB) {
        margin-bottom: $bufferSpaceS;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        float: none;
        svg {
            position: relative;
            width: 60px;
            height: 60px;
            fill: $cTealMedium;
            transform: translateX(-50%);
            left: 50%;
        }
        img {
            transform: translateX(-50%);
            left: 50%;
        }
    }
}

.stat__content {
    @include respond-to-max($vpB) {
        width: calc(100% - 95px);
        float: right;
    }
}

.stat__number {
    width: 75px;
    height: 75px;
    float: left;
    margin-right: $bufferSpaceS;
    background-color: $cTealMedium;
    @extend .heading;
    font-size: rem($typeXXL);
    color: #fff;
    text-align: center;
    line-height: 1.5;
    border-radius: $componentRadius;
    @include respond-to($vpB) {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $bufferSpaceS;
        float: none;
    }
}

.stat__heading {
    @extend .heading;
    font-size: rem($typeL);
    line-height: 1;
    i {
        font-style: normal;
        color: $cMustard;
    }
    b {
        font-style: normal;
        color: $cTurquoise;
    }
    &--large {
        font-size: rem($typeLL);
    }
    @include respond-to($vpB) {
        text-align: center;
        font-size: rem($typeLL);
        &--large {
            font-size: rem($typeXL);
        }
    }
}

.stat__text {
    font-size: rem($typeS);
    margin-top: 1em;
    @include respond-to($vpB) {
        text-align: center;
    }
}
