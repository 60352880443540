@use 'sass:math';

@import 'viewports.scss';

// General spacing options
// ------------------------------------------------------------------

$bufferSpaceS: 20px;
$bufferSpaceM: 40px;
$bufferSpaceL: 80px;


// Grid things
// ------------------------------------------------------------------

$gridSet: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; // 12 column grid
$gridMax: 1340px;
$gridGap: 50px;
$gridRowGap: $gridGap;
$gridBaseline: 8;


// Ratios
// ------------------------------------------------------------------

$ratioWidescreen: 56.25%;


// Buttons
// ------------------------------------------------------------------

$btnSmall:    30px;
$btnDefault:  35px;
$btnRadius:   5px;
$componentRadius: 8px;
$btnPadding:  15px;
$btnPaddingSmall: math.div($btnPadding,1.25);


// Misc
// ------------------------------------------------------------------

$formItem: 40px;
$strokeWidth: 1px;
