.page-global-page .dynamic-list {
    &.grid-block--1 {
        .dynamic-item {
            margin: 0 auto;
            max-width: 400px;
        }
    }
}

.dynamic-list {
    .card {
        .card__content h3 {
            margin-top: 0;
        }
        .card__media {
            display: inline;
        }
    }
}
