@use 'sass:math';

html {
  font-size: $scaleRoot + px;
  scroll-behavior: smooth;
  @media (max-width: $vpDeviceSafe) {
    -webkit-text-size-adjust: 100%;
  }
}

body {
  color: $cText;
  font-family: $fontDefault;
  line-height: $lineheightBase;
  overflow-x: hidden;
  background-color: $cCream;
}

.body--theory-of-change-interactive {
  background-color: $cCanvasLight;
}

.-nofocus {
  outline: none;
}

::selection {
  background: $cActionRespond;
  text-shadow: inherit;
}

a,
.fauxlink {
  text-decoration: none;
  cursor: pointer;
  color: $cText;
  border-bottom: 2px solid $cAction;
  @include link-active-styles {
    border-bottom: 2px solid $cActionRespond;
  }
}

.skiplink 	{
	background: #fff;
	color: $cAction;
	border: 1px solid $cBorder;
	display: block;
	font-size: 16px;
	line-height: 40px;
	padding: 0 20px;
	position: absolute;
	top: -60px;
	left: 0;
	transition: all ease-in-out 0.1s;
	&:focus,
	&:active {
		top: 0;
		z-index: 80;
	}
}

// Overide iOS telephone styling on links
a[href^="tel"] {
  color: inherit;
  text-decoration: none;
}

// Max with utl class
.max-width {
  max-width: 100%;
  height: auto;
  display: block;
}

// Max meter utl class
.max-meter {
  max-width: 800px;
}

// Full width utl class
.fulgrid-width {
  width: 100%;
  height: auto;
  display: block;
}

// Further sensible resets
strong, b {
  font-weight: $fontSemiBold;
	@include sharp-text;
}

input, textarea, select, button {
  font-family: $fontDefault;
}

@media print {
	body {
		font-size: percentage(math.div(10,16));
		color: #000!important;
		background-color: #fff!important;
	}
	*[role="navigation"],
	*[role="contentinfo"] {
		display: none!important;
  }
  .header, .banner, .header-search {
    display: none !important;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .button {
    padding: 0 !important;
    box-shadow: none !important;
    background: none !important;
  }
  .button--large, .button--utility {
    display: none !important;
  }
  .hero {
    padding: 0 !important;
    background-color: #fff !important;
  }
  .hero--image {
    background-image: none !important;
    &:after {
      content: none !important;
    }
  }
  .hero__credit {
    display: none !important;
  }
  .hero__heading {
    text-shadow: none !important;
    color: #000 !important;
  }
  .hero__lead {
    text-shadow: none !important;
    color: #000 !important;
  }
  .hero__highlight {
    box-shadow: none !important;
  }
  .hero__meta li {
    text-shadow: none !important;
    color: #000 !important;
  }
  .hero__aside .card__event {
    background-color: #fff !important;
    padding: 0 !important;
    text-align: left !important;
    color: #000 !important;
    margin-bottom: 50px !important;
    .card__event-day, .card__event-month, .card__event-year {
      display: inline-block;
    }
  }
.hero__media {
  display: none !important;
}
  .section--clip {
    margin-top: 0 !important;
    transform: translateY(0) !important;
    margin-bottom: 0 !important;
  }
  .pagination {
    display: none !important;
  }
  .hero__description {
    text-shadow: none !important;
    color: #000 !important;
  }
  .filter {
    display: none !important;
  }
  .panel {
    box-shadow: none !important;
    padding: 0 !important;
    .hero & {
      margin-top: 3em !important;
    }
  }
  .panel__action {
    display: none !important;
  }

  section.section {
    padding: 0;
  }
  .list-duo__media {
    display: none !important;
  }
  .list-duo__main {
    width: 100% !important;
    float: none !important;
  }
  .logos__item {
    border: none !important;
    a {
      text-indent: 0 !important;
      background-image: none !important;
      font-size: 16px;
    }
  }

  .duo__media {
    display: none;
  }

  .duo__content {
    padding: 0 !important;
    margin-left: 0 !important;
    box-shadow: none !important;
  }

  .media-figure, .media-video-wrapper {
    display: none !important;
  }

  .billboard__media {
    display: none !important;
  }

  .grid-2up,
  .grid-3up,
  .grid-34p {
        overflow: hidden;
        grid-row-gap: 0 !important;
  }

    .grid-2up__col,
    .grid-3up__col,
    .grid-4up__col {
        float: left;
        margin-left: 5%;
    }

    .grid-2up__col {
        width: 47.5%;
        &:nth-child(2n+1) {
            clear: left;
            margin-left: 0;
        }
    }

    .grid-3up__col {
        width: 30%;
        margin-left: 5%;
        &:nth-child(3n+1) {
            clear: left;
            margin-left: 0;
        }
    }

    .grid-4up__col {
        width: 21%;
        margin-left: 5%;
        &:nth-child(4n+1) {
            clear: left;
            margin-left: 0;
        }
    }
}
