@use 'sass:math';

.wp-caption, .media-figure {
    margin: math.div($bufferSpaceM,1.25) 0;
    img {
        @extend .max-width;
    }
}

.media-video-wrapper {
    display: block;
    margin: 0;
    position: relative;
    margin: math.div($bufferSpaceM,1.25) 0;
	@include ratio(16 9);
	object,embed,iframe  {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.wp-caption-text, .media-figure__caption {
    margin-top: 10px;
    border-left: 3px solid $cTurquoise;
    padding-left: 10px;
    font-size: rem($typeS);
    p {
        font-size: inherit;
    }
}

.alignleft, .left {
    float: left;
    margin: math.div($bufferSpaceM,1.25) math.div($bufferSpaceM,1.25) 0 0;
    img {
        @extend .max-width;
    }
}

.alignright, .right {
    float: right;
    margin: math.div($bufferSpaceM,1.25) 0 0 math.div($bufferSpaceM,1.25);
    img {
        @extend .max-width;
    }
}

// Wagtail edit button override
.wagtail-userbar__item a {
    border-bottom: none !important;
}
