
// ------------------------------------------------------------
// Placeholders
// ------------------------------------------------------------

// Show / hides
%is-hidden 				{ display: none; }
%is-shown-block 		{ display: block; }
%is-shown-inline 		{ display: inline; display: inline-block; }

// Type and rhythm
%is-uppercase 			{ text-transform: uppercase; letter-spacing: 0.1rem; }
%is-lowercase 			{ text-transform: lowercase; }
%is-titlecase 			{ letter-spacing: -0.1rem; }
%no-leader 				{ margin-top: 0; }
%no-trailer 			{ margin-bottom: 0; }

// Clearfixes
%clearfix 				{ overflow: hidden; *zoom: 1;}
%pie-clearfix 			{ &:after { content: ""; display: table; clear: both; *zoom: 1; } }
%ie8-clearfix           { &:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; } }

// Images
%max-width 				{ max-width: 100%; height: auto; }

