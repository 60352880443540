.panel-blocks {
    &.grid-block--1 {
        gap: 0;

        .panel-block {
            background: none;
            flex: 0 0 100%;
            overflow: visible;
            position: relative;
            margin-bottom: $bufferSpaceL;
            &:last-of-type {
                margin-bottom: 0;
            }
            @include respond-to($vpC) {
                display: flex;
                align-items: center;
                justify-content: left;
            }

            .panel-block__media {
                img {
                    @extend .max-width;
                    border-radius: $componentRadius;
                }
                @include respond-to($vpC) {
                    width: 50%;
                }
            }

            .panel-block__content {
                position: relative;
                z-index: 2;
                background-color: $cCanvas;
                padding: $bufferSpaceS;
                border-radius: $componentRadius;
                @extend %shadowStraightLarge;
                max-width: 90%;
                margin-top: -$bufferSpaceM;
                margin-left: auto;
                margin-right: auto;
                @include respond-to($vpB) {
                    padding: $bufferSpaceM;
                }
                @include respond-to($vpC) {
                    width: 60%;
                    position: absolute;
                    right: 0;
                    max-width: 100%;
                    margin: 0;
                }
                @include respond-to($vpD) {
                    padding: $bufferSpaceM*1.5;
                }
            }

            &:nth-child(even) {
                .panel-block__media {
                    @include respond-to($vpC) {
                        margin-left: auto;
                    }
                }
                .panel-block__content {
                    @include respond-to($vpC) {
                        left: 0;
                        right: auto;
                    }
                }
            }
        }
    }
    &.grid-block--2 {
        .panel-block__media img {
            @include respond-to($vpB) {
                height: 100%;
                width: 100%;
                object-fit: cover;
                max-height: 250px;
            }
        }

        .panel-block__content {
            @include respond-to($vpB) {
                padding: $bufferSpaceM;
            }
        }
    }
}

.panel-block {
    position: relative;
    display: block;
    align-items: center;
    margin-bottom: 15px;
    overflow: hidden;
    background: white;
    border-radius: 8px;

    &__media {
        width: 100%;
        overflow: hidden;

    }

    &__content {
        width: 100%;
        padding: $bufferSpaceS;
        max-width: 100%;
        z-index: 2;
        background-color: #fff;

        h3 {
            margin: 0;
        }
    }
}
