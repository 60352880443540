
// Better local fonts
@font-face {
  font-family: 'adellelight';
  src: url('/assets/fonts/adelle_light.woff2') format('woff2'),
       url('/assets/fonts/adelle_light.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'adellelight_italic';
  src: url('/assets/fonts/adelle_lightitalic.woff2') format('woff2'),
       url('/assets/fonts/adelle_lightitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'adellesemibold';
  src: url('/assets/fonts/adelle_semibold.woff2') format('woff2'),
       url('/assets/fonts/adelle_semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'adellesemibold_italic';
  src: url('/assets/fonts/adelle_semibolditalic.woff2') format('woff2'),
       url('/assets/fonts/adelle_semibolditalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'platzregular';
  src: url('/assets/fonts/platzregular-webfont.eot');
  src: url('/assets/fonts/platzregular-webfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/platzregular-webfont.woff') format('woff'),
       url('/assets/fonts/platzregular-webfont.ttf') format('truetype'),
       url('/assets/fonts/platzregular-webfont.svg#PlatzRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'platzcondensed';
  src: url('/assets/fonts/platzcondensed-webfont.eot');
  src: url('/assets/fonts/platzcondensed-webfont.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/platzcondensed-webfont.woff') format('woff'),
       url('/assets/fonts/platzcondensed-webfont.ttf') format('truetype'),
       url('/assets/fonts/platzcondensed-webfont.svg#PlatzRegular') format('svg');
  font-weight: normal;
  font-style: normal;

}