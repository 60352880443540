.data-source {
    background-color: $cCanvasLight;
    padding: $bufferSpaceS*1.5;
    margin-top: $bufferSpaceM;
    border-radius: $componentRadius;

    @include respond-to($vpB) {
        padding: $bufferSpaceM;
    }

    h3.data-source__heading {
        margin-top: 0;
    }

    &__list {
        margin-bottom: 1em;

        p {
            margin: 0;
            @extend %typebaseRoot;
            font-size: rem($typeS);
        }
    }

}