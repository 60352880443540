
// Brand colours
// ------------------------------------------------------------------

// Primary colours
$cMustard:       #faa819!default;
$cTurquoise:     #66cbe1!default;

// Secondary colours
$cScarlet:       #dd3626!default;
$cCerise:        #ae4c90!default;
$cJade:          #a7d6a6!default;

// Framework colours
$cTealDark:      #27414e!default;
$cTealMedium:    #1a6473!default;
$cTealLight:     #2F93A8!default;

// Typography colours
$cSlateGrey:     #333434!default;
$cDoveGrey:      #a6b3bc!default;
$cSalmon:        #fa6152!default;

// Neutral colours
$cCanvasDark:    #ddd8c6!default;
$cCanvasMedium:  #e4e2d8!default;
$cCanvasLight:   #f1f0ec!default;
$cCream:         #faf9f5!default;
$cCanvas:        #ffffff!default;

// Theory of Change 
$cTheoryOrange:    #ff9f67 !default;
$cTheoryYellow:    #ffd060 !default;
$cTheoryBlue:      #75cae3 !default;
$cTheoryBlueDark:  #2f93a8 !default;

// Variants
$cJadeDark: darken($cJade,10%);

// Non-brand feedbacks
// ------------------------------------------------------------------
$cError: 		 #B10E1E;
$cSuccess: 		 #00823B;
$cAlert: 		 #fff347;
$cNotice: 		 #7bbccc;


// Assign colours to declarative variables
// ------------------------------------------------------------------

// Actions
$cAction: 		   $cMustard;
$cActionRespond:   $cTurquoise;
$cActionVisited:   $cTurquoise;

// Buttons
$cButtonPrimary:   $cMustard;
$cButtonSecondary: $cTurquoise;
$cButtonTertiary:  $cTealMedium;
$cButtonDisabled:  $cDoveGrey;
$cButtonDanger:    $cError;
$cButtonSuccess:   $cSuccess;

// Default text colors
$cHeading: 		   $cSlateGrey;
$cLabel:           $cSlateGrey;
$cText: 		   $cSlateGrey;

// Misc. color assignments
$cBorder:          $cCanvasLight;
$cDisabled:        $cDoveGrey;