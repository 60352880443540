@use 'sass:math';

.highlight {
    background-color: $cCanvas;
    padding: $bufferSpaceS*1.5;
    margin-top: $bufferSpaceM;
    border-radius: $componentRadius;
    @include respond-to($vpB) {
        padding: $bufferSpaceM;
    }
    .button {
        margin-top: $bufferSpaceS;
    }
    &--alt {
        background-color: $cCanvasLight;
    }
    &--paddless {
        margin-top: 0;
    }
    &--styled {
        background-color: $cJade;
    }
    &--trailer {
        margin-bottom: $bufferSpaceM;
    }
    &--light {
    	background-color: #fff;
    }
    .grid-3up &, .grid-2up &, .grid-1up &, .grid-4up & {
        margin-top: 0;
    }
}

.highlight__heading {
    @extend .heading;
    line-height: 1;
    font-size: rem($typeL);
    margin-bottom: $bufferSpaceS;
    .highlight--large & {
        font-size: rem($typeXL);
    }
}

.highlight__grid {
    @include respond-to($vpC) {
        display: flex;
        .highlight__list {
            padding-right: $bufferSpaceS;
            width: 50%;
        }
    }
}

.highlight__list {
    position: relative;
    font-size: rem($typeDefault);
    list-style: none;
    flex-grow: 1;
    li {
        margin-top: math.div($bufferSpaceS,2);
        a {
            border-bottom: none;
            text-decoration: underline;
            white-space: pre-wrap; /* CSS3 */
            white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
            white-space: -pre-wrap; /* Opera 4-6 */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word; /* Internet Explorer 5.5+ */
        }
        svg {
            width: 19px;
            height: 19px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
}

.highlight__list__heading {
    @extend .heading;
    font-size: rem($typeM);
}

.highlight__link {
    font-size: rem($typeDefault);
    margin-top: $bufferSpaceS;
    display: block;
    border-bottom: none;
    span {
        border-bottom: 2px solid $cAction;
        display: inline;
    }
    @include link-active-styles {
        border-bottom: none;
        span {
            border-bottom: 2px solid $cActionRespond;
        }
    }
}

.highlight {
    &--jade {
        background-color: $cJade;
    }
    &--mustard {
        background-color: $cMustard;
        a {
            border-color: currentColor;

            @include link-active-styles {
                border-color: $cActionRespond;
            }
        }
    }
    &--turquoise {
        background-color: $cTurquoise;
    }
    &--scarlet {
        background-color: $cScarlet;
        color: white;
        h2,h3,h4,h5,a,
        .button {
            color: white;
        }
    }
    &--cerise {
        background-color: $cCerise;
        color: white;
        h2,h3,h4,h5,a,
        .button {
            color: white;
        }
    }
    &--canvasLight {
        background-color: $cCanvasLight;
    }
    &--mediumTeal {
        background-color: $cTealMedium;
        color: white;
        h2,h3,h4,h5,a,
        .button {
            color: white;
        }
    }
    &--lightTeal {
        background-color: $cTealLight;
        color: black;
        h2,h3,h4,h5,a,
        .button {
            color: black;
        }
    }
}