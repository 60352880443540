.grid-block.colour-tile-blocks {
    gap: 0;
    margin-bottom: 0;

    &.tpr-1 {
        .colour-tile-content {
            @include respond-to($vpB) {
                width: 50%;
            }
        }
    }
}

.colour-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: $bufferSpaceM $bufferSpaceS;

    &__content {
        width: 100%;
        max-width: 100%;
        z-index: 2;

        h3 {
            margin: 0;
        }
    }

    &__buttons {
        margin-top: $bufferSpaceS;

        .button {
            background-color: transparent;
            border: 2px solid;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        }
    }
}

.colour-tile {
    &--jade {
        background-color: $cJade;
    }
    &--mustard {
        background-color: $cMustard;

        a {
            border-color: currentColor;

            @include link-active-styles {
                border-color: $cActionRespond;
            }
        }
    }
    &--turquoise {
        background-color: $cTurquoise;
    }
    &--scarlet {
        background-color: $cScarlet;
        color: white;
        h2,h3,h4,h5,a,
        .button {
            color: white;
        }
    }
    &--cerise {
        background-color: $cCerise;
        color: white;
        h2,h3,h4,h5,a,
        .button {
            color: white;
        }
    }
    &--canvasLight {
        background-color: $cCanvasLight;
    }
    &--mediumTeal {
        background-color: $cTealMedium;
        color: white;
        h2,h3,h4,h5,a,
        .button {
            color: white;
        }
    }
    &--lightTeal {
        background-color: $cTealLight;
        color: black;
        h2,h3,h4,h5,a,
        .button {
            color: black;
        }
    }
}
