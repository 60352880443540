@use 'sass:math';

.bar-chart {
	margin-bottom: $bufferSpaceM;
	display: table;
	width: 100%;
	caption,
	thead {
		display: none;
	}
	font-size: rem($typeDefault);
	font-family: $fontBrand;
	.position{
		width: 40px;
		text-align: center;
		background-color: $cCanvasLight;
		border-bottom: 2px solid #fff;
	}
	.bar-chart__row {
		border-bottom: 2px solid transparent;
	}
	.bar-chart__bar {
		position: relative;
		background-color: #eee;
		a:hover, a:focus {
			.bar__background {
				background-color: $cJadeDark;
			}
		}
		// background lines every 10%
		// background: repeating-linear-gradient(
		// 	to right,
		// 	#ddd,
		// 	#ddd 1px,
		// 	#fff 1px,
		// 	#fff 10%
		// );
		// border-right: 1px solid #ddd;
	}
	.bar__content{
		position: relative;
	    z-index: 2;
	    padding: 8px;
	    line-height: 1.2;
	}
	.bar__background{
		position: absolute;
		top: 0;
	    bottom: 0;
	    left: 0;
	    background-color: $cJade;
	    z-index: 1;
	}
}