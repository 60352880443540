.logos {
    list-style: none;
    @include gridCalcRepeat(2, 10px, 10px);
    @include respond-to($vpC) {
        @include gridCalcRepeat(4, 10px, 10px);
    }
    @include respond-to($vpD) {
        @include gridCalcRepeat(8, 10px, 10px);
    }
}

.logos__item {
    background-color:$cCanvas;
    border: 1px solid $cBorder;
    transform: scale(1);
    transition: all, ease-in-out, 0.3s;
    &:hover {
        @extend %shadowStraightLarge;
        transform: scale(1.1);
    }
    a {
        background-position: center;
        min-height: 120px;
        background-size: 100px;
        display: block;
        border-bottom: none;
        text-indent: -9999em;
    }
    img {
        @extend .max-width;
    }
}

.typebase {
    .logos {
        list-style: none;
        padding-left: 0px;
    }
}
