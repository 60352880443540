@use 'sass:math';

.m-embed {
    position: relative;
    height: 100vh;
    margin-top: $bufferSpaceL;
    &__typeform {
      height: auto;
      margin-top: 0;
    }
	object,embed,iframe,#gnb-theory-of-change  {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&--form {
		height: 500vh;
		@include respond-to($vpC) {
			height: 360vh;
		}
		@include respond-to($vpE) {
			height: 240vh;
		}
	}
}

.m-share {
	overflow: hidden;
}

.m-share__heading {
	@extend .heading;
	font-size: rem($typeM);
	display: inline-block;
}

.m-share__list {
	display: inline-block;
	transform: translateY(5px);
	li {
		display: inline-block;
		margin-left: 14px;
		svg {
			width: 25px;
            height: 25px;
            display: block;
            vertical-align: middle;
		}
	}
}

.m-funding {
	&__copy {
		font-size: rem($typeXS);
		text-transform: uppercase;
		display: block;
		letter-spacing: 1px;
	}
	&__logo {
		height: auto;
		width: 130px;
		img {
			@extend .max-width;
		}
	}
}

.m-media {
	img {
		@extend %max-width;
	}
}

// YouTube background video experiment
.m-videobkg {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -99;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}
}
.richtext-image {
    margin: math.div($bufferSpaceM,1.25);
    width: min(500px, 50%);

    &.left {
        margin-left: 0;
    }

    &.right {
        margin-right: 0;
    }

    &.full-width {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    &.thumbnail {
        width: unset;
    }
}

.typebase--article {
    hr {
        margin: math.div($bufferSpaceM,1.25) 0;
    }

    .m-members .section__heading p {
        font-size: inherit;
        line-height: inherit;

        .integer-text {
            color: $cMustard;
        }

    }

    .section__title {
        margin-bottom: $bufferSpaceS;
    }

    .media-figure {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
    }

    // I'm not sure this is wanted...
    // Will restrict width of text & a few other blocks on global pages
    .rich-text {
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;

        & + * {
            clear: both;
        }
    }

    &.page-global-page {
        .carousel.flickity-enabled,
        .media-figure,
        .html-block {
            max-width: 750px;
            margin-left: auto;
            margin-right: auto;
        }

        .html-block {
            margin-top: 32px;
            margin-bottom: 32px;
        }

        .highlight,
        .section .logo-card,
        .blockquote,
        .data-source {
            max-width: 850px;
            margin-left: auto;
            margin-right: auto;
        }

        .button-group {
            display: flex;
            justify-content: center;
        }
    }
}

.grid-block {
    display: grid;
    gap: $bufferSpaceS;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: $bufferSpaceS;

    @include respond-to($vpC) {
        gap: $gridGap;
    }

    &--2 {
        @include respond-to($vpC) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &--3 {
        @include respond-to($vpC) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &--4 {
        @include respond-to($vpC) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

.page-article {
    .logos {
        @include respond-to($vpD) {
            @include gridCalcRepeat(4, 10px, 10px);
        }
    }
}
.page-global-page {
    .logos {
        @include respond-to($vpB) {
            @include gridCalcRepeat(4, 10px, 10px);
        }
    }
    .logos {
        @include respond-to($vpD) {
            @include gridCalcRepeat(8, 10px, 10px);
        }
    }
}
