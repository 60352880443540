@use 'sass:math';

.aside {
    background-color: $cCanvas;
    padding: $bufferSpaceS;
    border-radius: $componentRadius;
    @include respond-to($vpB) {
        padding: $bufferSpaceM;
    }
    + .aside {
        margin-top: $bufferSpaceM;
    }
    .button {
        margin-top: math.div($bufferSpaceS,2);
    }
}

#append-nav-desktop, #append-nav-mobile {
    margin-bottom: $bufferSpaceM;
    .side-bar__main & {
        @include respond-to($vpMenu) {
            margin-bottom: 0;
            html.no-js & {
                margin-bottom: $bufferSpaceM;
            }
        }
    }
}

#append-nav-desktop {
    @include respond-to-max($vpMenu) {
        display: none;
    }
}

.aside__media {
    overflow: hidden;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    // display: none;
    background-color: $cTealLight;
    min-height: 250px;
    // @include respond-to($vpC) {
    //     display: block;
    // }
    & + .aside__heading {
    	margin-top: $bufferSpaceS;
    }
}

.aside__heading {
    position: relative;
    @extend .heading;
    line-height: 1.3;
    font-size: rem($typeM);
    margin-bottom: $bufferSpaceS;
    &--minor {
        margin-bottom: 0;
    }
}

.aside__iconlist {
    font-size: rem($typeDefault);
    list-style: none;
    li {
        margin-bottom: math.div($bufferSpaceS,2);
        a {
            border-bottom: none;
            text-decoration: underline;
        }
        svg {
            width: 19px;
            height: 19px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
}

.aside__list {
    font-size: rem($typeDefault);
    list-style: none;
    line-height: 1.4;
    > li {
        @include pie-clearfix;
        border-bottom: 1px solid $cBorder;
        padding-bottom: math.div($bufferSpaceS,1.5);
        margin-bottom: math.div($bufferSpaceS,1.5);
        .aside__list__heading {
            font-family: 'adellesemibold';
            margin-bottom: 0.25em;
            display: block;
        }
        ul {
            list-style: none;
            li {
                display: block;
                padding-top: math.div($bufferSpaceS,3.2);
                &:first-child{
                	padding-top: 0;
                }
            }
        }
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    + .aside__heading {
        margin-top: $bufferSpaceS;
    }
    &--simple {
        li {
            border-bottom: none;
            padding-bottom: 0;
        }
        + .aside__list--simple {
            margin-top: $bufferSpaceM;
        }
    }
}

.aside__navigation-toggle {
    @extend .navigation-primary-toggle;
    position: absolute;
    top: 0 !important;
    right: 0 !important;
    z-index: 1!important;
    &--open {
        @extend .navigation-primary-toggle--open;
    }
    html.no-js & {
        display: none;
    }
}

.aside__navigation {
    overflow: hidden;
    font-size: rem($typeDefault);
    list-style: none;
    @include respond-to($vpC) {
        margin-top: $bufferSpaceS;
    }
    html.js & {
        @include respond-to-max($vpC) {
            max-height: 0;
            transition: all, ease-in-out, 0.3s;
        }
    }
    &--open {
        html.js & {
            @include respond-to-max($vpC) {
                max-height: 800px;
                margin-top: $bufferSpaceS;
            }
        }
    }
}

.aside__navigation-heading {
    font-size: rem($typeXS);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: math.div($bufferSpaceS,2);
}

.aside__navigation-item {
    position: relative;
    transition: all, ease-in-out, 0.3s;
    border-bottom: 1px solid $cBorder;
    a {
        border-bottom: none;
        color: $cTealMedium;
        transition: all, ease-in-out, 0.3s;
        padding: 0.5em 0 0.5em 0;
        display: block;
    }
    @include link-active-styles {
        a {
            color: $cHeading;
            background-color: $cCream;
            border-left: 2px solid $cActionRespond;
            padding-left: 0.5em;
        }
    }
    &--active {
        a {
            color: $cHeading;
            background-color: $cCream;
            border-left: 2px solid $cActionRespond;
            padding-left: 0.5em
        }
    }
}
