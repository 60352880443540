.accordion {
    margin-top: $bufferSpaceS;

    &__button {
        color: $cSlateGrey;
        text-align: left;
        width: 100%;
        display: flex;
        align-items: center;
        border: none;
        border-radius: 8px;
        padding: $bufferSpaceS;
        cursor: pointer;
        background-color: white;
        transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s, box-shadow ease-in-out 0.3s, border-radius ease-in-out 0.3s 0.3s;

        svg {
            height: 25px;
            width: 25px;
            flex-shrink: 0;
            transition: color ease-in-out 0.3s, transform ease-in-out 0.3s;
            margin-right: 10px;
        }

        h3 {
            color: inherit;
            transition: color ease-in-out 0.3s;
        }

        &:hover {
            box-shadow: 0px 10px 20px rgba(221,216,198,.6);
            color: $cTealMedium;

            svg {
                transform: rotate(45deg);
            }
        }

        .is-active & {
            border-radius: 8px 8px 0 0;
            transition: background-color ease-in-out 0.3s;
            background-color: $cTealMedium;
            color: white;

            svg {
                transform: rotate(45deg);
            }
        }
    }

    &__body {
        background-color: white;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
        transition: max-height ease-in-out 0.3s;

        &-wrapper {
            padding: $bufferSpaceS;
        }

        .is-hidden & {
            opacity: 0;
            visibility: hidden;
            padding: 0;
            max-height: 0;
            transition:
                max-height ease-in-out 0.3s,
                visibility 0s 0.3s linear,
                opacity 0s 0.3s linear,
                padding 0s 0.3s linear;
        }
    }
}
