@use 'sass:math';

// Root base
// ------------------------------------------------------------------

%typebaseRoot {
	line-height: $lineheightBase;
	margin-bottom: 0;
	margin-top: 0;
}

// Kerning
// ------------------------------------------------------------------

%kernTight 				{ letter-spacing: -0.125rem; font-kerning: normal; }
%kernLoose 				{ letter-spacing: 0.3rem; }
%kernUltraLoose 	{ letter-spacing: 0.76rem; }

// Typescale
// ------------------------------------------------------------------

.typeXXL {
	@extend %typebaseRoot;
	@include sharp-text;
	font-size: rem($typeXXL);
	line-height: 1.2;
	margin-bottom: 0.75em;
}

.typeXL {
	@extend %typebaseRoot;
	font-size: rem($typeXL);
}

.typeL {
	@extend %typebaseRoot;
	font-size: rem($typeL);
}

.typeM {
	@extend %typebaseRoot;
	font-size: rem($typeM);
}

// Median
.typeMe {
	@extend %typebaseRoot;
	font-size: rem($typeMe);
}

.typeS {
	@extend %typebaseRoot;
	font-size: rem($typeS);
}

.typeXS {
	@extend %typebaseRoot;
	font-size: rem($typeXS);
}


// Heading styles
// ------------------------------------------------------------------

.heading {
	display: block;
	font-family: $fontHeading;
	color: $cHeading;
	@include sharp-text;
	line-height: 1.2;
}

// Default text
// ------------------------------------------------------------------

.text {
	@extend %typebaseRoot;
	font-size: rem($typeDefault);
}

.text--small {
	@extend %typebaseRoot;
	font-size: rem($typeS);
}

// Default lists
// ------------------------------------------------------------------

%textList {
	li {
		@extend .text;
	}
}

%listlead {
	padding-bottom: rem(math.div($typeDefault,2));
	&:last-of-type {
		padding-bottom: 0;
	}
}

.unordered_list {
	list-style-type: disc;
	@extend %textList;
	ul {
		list-style-type: disc;
		padding-left: rem(20);
	}
}

.ordered_list {
	list-style-type: decimal;
	@extend %textList;
	ol {
		list-style-type: decimal;
		padding-left: rem(20);
	}
}

.simple_list {
	list-style-type: none;
	@extend %textList;
	ol,ul {
		list-style-type: none;
		padding-left: rem(20);
	}
}

.description_list {
	overflow: hidden;
	margin-left: 0;
	margin-right: 0;
	dt,dd {
		@extend .text;
		@include leading_adjust(2);
		margin-left: 0;
		margin-right: 0;
	}
	dt {
		display: block;
	}
	dd {
		display: block;
		margin-left: rem(18);
		+ dt {
			margin-top: rem(18);
		}
	}
	code {
		display: inline;
	}
}

// Quote styles
// ------------------------------------------------------------------

.blockquote {
	margin: $bufferSpaceM 0;
	border-left: 10px solid $cTealLight;
	padding-left: 2em;
    display: flex;
    gap: 2em;
    max-width: 700px;

    @include respond-to-max($vpC) {
        flex-direction: column;
    }

	p {
		font-size: rem($typeM);
	}

	cite {
		font-size: rem($typeS);
		margin-top: 1em;
		display: block;
        text-align: right;
	}
	svg {
		width: 45px;
		height: 45px;

        path {
            fill: $cMustard;
        }
	}
	&_image {
        border-radius: 50%;
        margin: auto 0;

        @include respond-to-max($vpC) {
            order: 1;
        }
	}
	&_copy {
        flex: 1 1 0;
	}
}

// Typebase (for runs of text)
// ------------------------------------------------------------------

.typebase {
	p {
		@extend .text;
		margin-top: rem($typeDefault);
		display: block;
	}
	ul,
	ol {
		@extend %textList;
		margin-top: rem($typeDefault);
	}
	dl {
		margin: rem($typeDefault*1.5) 0;
		@extend .description_list;
	}
	ul {
		@extend .unordered_list;
		padding-left: 2em;
	}
	ol {
		@extend .ordered_list;
		padding-left: 2em;
	}
	li ul,
	li ol {
		margin-top: 0.8rem;
	}
	li {
		@extend %listlead;
	}
	img {
		// display: block;
		max-width: 100%;
		height: auto;
	}
	h1, h2, h3, h4, h5, h6 {
		@extend .heading;
		position: relative;
		margin-top: rem($typeDefault*2.5);
		scroll-margin-top: 25px;
	}
	h1 {
		@extend .typeXXL;
	}
	h2 {
		@extend .typeXL;
	}
	h3 {
		@extend .typeL;
	}
	h4 {
		@extend .typeM;
	}
	h5, h6 {
		@extend .typeMe;
	}

	// Remove next para or list top margin
	h1 + p,
	h2 + p,
	h3 + p,
	h4 + p,
	h5 + p,
	h6 + p,
	h1 + ol,
	h2 + ol,
	h3 + ol,
	h4 + ol,
	h5 + ol,
	h6 + ol,
	h1 + ul,
	h2 + ul,
	h3 + ul,
	h4 + ul,
	h5 + ul,
	h6 + ul {
		margin-top: rem(math.div($typeDefault,2));
	}

	// Tear out the initial top margin to allow things to flush together
	> h1:first-child,
	> h2:first-child,
	> h3:first-child,
	> h4:first-child,
	> h5:first-child,
	> h6:first-child,
	> img:first-child,
	> p:first-child,
	> ol:first-child,
	> ul:first-child,
	> .media-video-wrapper:first-child  {
		margin-top: 0;
	}
	> .media-figure:first-child {
		margin-top: 0;
	}

	// Stop empty spacing
	br + br,
	h1:empty,
	h2:empty,
	h3:empty,
	h4:empty,
	h5:empty,
	h6:empty,
	p:empty,
	ul:empty,
	ol:empty,
	span:empty,
	cite:empty,
	div:empty {
		display: none;
	}
}

.typebase--article {
	& > p,
    .rich-text p {
		font-size: rem($typeDefaultL);
        margin: math.div($bufferSpaceM,1.25) 0 0;
	}
	& > ul li,
	& > ol li,
    .rich-text ul li,
    .rich-text ol li {
		font-size: rem($typeDefaultL);
	}
    & > .button,
    .rich-text .button {
        font-size: rem($typeDefaultL);
    }
}

// Highlighting

.type--highlight{ // used on region single page
	font-weight: bold;
	font-family: $fontVoice;
	font-size: rem($typeXXL);
	line-height: 1;
	color: $cMustard;
}

.text-highlight {
	background-color: $cMustard;
}