@use 'sass:math';

.header {
    position: relative;
    z-index: 2;
    background-color: $cCanvas;
    padding: 15px 0 15px 0;
    &:after {
        content: "";
        position: absolute;
        display: block;
        bottom: -30px;
        left: 0;
        background-image: url(/assets/img/svg/header-brush.svg);
        background-size: contain;
        background-repeat: repeat-x;
        background-position: center;
        z-index: -1;
        width: 100%;
        height: 80px;
    }
    @include respond-to($vpB) {
        padding: $bufferSpaceS 0 math.div($bufferSpaceS,2) 0;
    }
    @include respond-to($vpMenu) {
        padding: $bufferSpaceS 0 0 0;
    }
}

.header__logo {
    width: 151px;
    height: 60px;
    display: inline-block;
    background-image: url(/assets/img/svg/logo.svg);
    background-size: contain;
    text-indent: -9999em;
    border-bottom: none;
    &:hover, &:focus {
        border-bottom: none;
    }
    &:lang(fr) {
        background-image: url(/assets/img/svg/logo-fr.svg);
        transform: translateY(8px);
    }
    @include respond-to($vpB) {
        height: 70px;
        width: 176px;
	}
    @include respond-to($vpMenu) {
        float: left;
        width: 208px;
        height: 83px;
        margin-right: 20px;
	}
}

.header-search-toggle {
    display: none;
    @include respond-to($vpMenu) {
        overflow: hidden;
        position: absolute;
        z-index: 100;
        top: -142px;
        right: -50px;
        display: block;
        background-color: $cTealMedium;
        padding: 146px 10px 10px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        transition: all, ease-in-out, 0.3s;
        cursor: pointer;
        &:focus {
            outline: none;
        }
        svg {
            width: 23px;
            height: 23px;
        }
        &--open {
            top: 0;
        }
    }
}

.header-search {
    position: relative;
    background-color: $cTealMedium;
    padding: $bufferSpaceS 0 $bufferSpaceM 0;
    transition: all, ease-in-out, 0.3s;
    html.js & {
        top: -100%;
        position: absolute;
    }
    width: 100%;
    z-index: 20;
    form {
        position: relative;
        label {
            display: block;
        }
        input {
            font-size: rem($typeS);
            background-color: $cCanvas;
            border: none;
            border-radius: $btnRadius;
            padding: 0.5em 1em;
            width: 100%;
        }
        .button {
            position: absolute;
            top: 43px;
            right: 0;
            height: 39px;
            line-height: 1;
            @include link-active-styles {
                transform: translateY(0);
            }
        }
    }
    &--open {
        html.js & {
            top: 0;
        }
    }
    &--body {
        border-radius: $componentRadius;
        html.js & {
            top: 0;
            position: relative;
        }
    }
}

.header-search__label {
    font-size: rem($typeL);
    font-family: $fontHeading;
    color: $cCanvas;
    @include sharp-text;
}