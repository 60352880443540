
$shadowAngle: -5px 10px 5px rgba(0, 0, 0, 0.1);
$shadowStraight: 0px 4px 8px rgba(0, 0, 0, 0.1);
$shadowForm: 0px 2px 5px rgba($cSlateGrey, 0.1);
$shadowStraightLarge: 0px 10px 20px rgba($cCanvasDark, 0.6);
$shadowStraightLargeDark: 0px 10px 20px rgba($cSlateGrey, 0.6);
$shadowStraightLargeMedium: 0px 10px 20px rgba($cSlateGrey, 0.2);
$shadowStraightDark: 0px 10px 5px rgba(0, 0, 0, 0.2);
$shadowContrast: 0px 1px 6px rgba(0, 0, 0, 0.2);

// Drop dropshadows
%shadowAngle {
  box-shadow: $shadowAngle;
}

%shadowAngleFilter {
  filter: drop-shadow($shadowAngle);
}

%shadowStraight {
  box-shadow: $shadowStraight;
}

%shadowStraightLarge {
  box-shadow: $shadowStraightLarge;
}

%shadowStraightLargeDark {
  box-shadow: $shadowStraightLargeDark;
}

%shadowStraightLargeMedium {
  box-shadow: $shadowStraightLargeMedium;
}

%shadowStraightDark {
  box-shadow: $shadowStraightDark;
}

%shadowForm {
  box-shadow: $shadowForm;
}

// Filter
%shadowStraightFilter {
  filter: drop-shadow($shadowStraight);
}

// Icons
%shadowIconFilter {
  filter: drop-shadow($shadowContrast);
}

// Text
%shadowStraightText {
  text-shadow: $shadowContrast;
}
