.footer {
    position: relative;
    background-color: $cCanvas;
    padding: $bufferSpaceM 0;
    margin-top: 25em;
    &:before {
        content: "";
        position: absolute;
        display: block;
        top: -40px;
        left: 0;
        background-image: url(/assets/img/svg/footer-brush.svg);
        background-size: contain;
        background-repeat: repeat-x;
        background-position: center;
        z-index: -1;
        width: 100%;
        height: 80px;
    }
}

.footer__trigger {
    position: absolute;
    display: block;
    top: -10em;
    right: $bufferSpaceM;
    background-color: $cTealMedium;
    border-radius: $btnRadius;
    font-size: rem($typeS);
    border-bottom: none;
    line-height: 1;
    padding: 10px 15px;
    text-align: center;
    color: $cCanvas;
    svg {
        width: 15px;
        height: 15px;
        display: block;
        transform: rotate(180deg);
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
    }
    @include link-active-styles {
        border-bottom: none;
        background-color: darken($cTealMedium, 5%);
    }
    @include respond-to($vpMenu) {
        top: -12em;
    }
}

.footer__main {
    @include respond-to($vpMenu) {
        width: 62.5%;
        margin-bottom: 60px;
    }
}

.footer__aside {
    position: relative;
    background-color: $cJade;
    padding: $bufferSpaceS $bufferSpaceS 160px;
    transform: translateY($bufferSpaceM);
    margin-top: $bufferSpaceM;
    &:before {
        content: "";
        position: absolute;
        display: block;
        top: -40px;
        left: 0;
        background-image: url(/assets/img/svg/newsletter-brush-top.svg);
        background-size: contain;
        background-repeat: repeat-x;
        width: 100%;
        height: 80px;
        z-index: -1;
    }
    @include respond-to($vpMenu) {
        position: absolute;
        margin-top: 0;
        right: 0;
        top: -50px;
        padding: 100px 50px;
        height: calc(100% + 50px);
        width: 35%;
        transform: translateY(0px);
        &:after {
            content: "";
            position: absolute;
            display: block;
            top: -21px;
            left: -19px;
            background-image: url(/assets/img/svg/newsletter-brush-left.svg);
            background-size: contain;
            background-repeat: repeat-Y;
            width: 80px;
            height: calc(100% + 21px);
            z-index: -1;
        }
    }
    .text {
        margin: $bufferSpaceS 0;
    }
    @include respond-to($vpMax) {
        .text {
            max-width: 300px;
        }
    }
}

.footer__list {
    font-size: rem($typeS);
    list-style: none;
    columns: 2;
    li {
        margin-bottom: 0.75em;
        svg {
            width: 15px;
            height: 15px;
            vertical-align: middle;
            margin-right: 5px;
            &.icon-instagram-svg {
                fill: #E1306C;
            }
        }
    }
    a {
        border-bottom: none;
        @include link-active-styles {
            text-decoration: underline;
        }
    }
}

.footer__meta {
    font-size: rem($typeS);
    margin:  $bufferSpaceM 0;
}

.footer__additional {
    @include pie-clearfix;
    border-top: 1px solid $cBorder;
    padding-top: $bufferSpaceM;
}

.footer__additional-credit {
    font-size: rem($typeS);
    float: left;
    width: 50%;
    a {
        text-decoration: underline;
        border-bottom: none;
    }
}

.footer__additional-list {
    font-size: rem($typeS);
    float: right;
    width: 50%;
    text-align: right;
    li {
        display: inline-block;
        margin-left: 1em;
        a {
            text-decoration: underline;
            border-bottom: none;
        }
    }
}