@use 'sass:math';

// Button defaults
// ------------------------------------------------------------------

// Button base style
%button {
	cursor: pointer;
	position: relative;
	padding: math.div($btnPadding,2) $btnPadding;
	vertical-align: middle;
	text-align: center;
	color: $cHeading;
	text-decoration: none;
	display: inline-block;
	border-radius: $btnRadius;
	transition-property: color;
	transition-duration: 0.5s;
	background-image: none;
	text-align: center;
	transition: all linear 0.3s;
	font-family: $fontHeading;
	@include sharp-text;
	span {
		font-size: inherit;
		font-weight: inherit;
	}
	&:focus {
		outline: none;
	}
}

// Sizes
// ------------------------------------------------------------------

.button,
a.button,
button.button {
	@extend %button;
	font-size: rem($typeDefault);
	background-color: $cButtonPrimary;
	box-shadow: 0px 4px 8px rgba($cButtonPrimary, 0.25);
	transition-duration: 0.1s;
	border-bottom: none;
	@include link-active-styles {
		transition-duration: 0.1s;
		background-color: $cButtonSecondary;
		box-shadow: 0px 4px 10px rgba($cButtonSecondary, 0.25);
		transform: translateY(-1px);
	}

	// Sizes
	&--small {
		font-size: rem($typeS);
		line-height: $btnSmall;
		padding: 0 $btnPaddingSmall;
	}
	&--large {
		padding: $btnPadding $btnPadding*2;
	}

	&--leader {
		margin-top: $bufferSpaceM;
	}

	&--trailer {
		margin-bottom: $bufferSpaceS;
	}

	&--center {
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		display: inline-block;
		@include link-active-styles {
			transform: translateX(-50%);
		}
	}
	&--inline {
		padding: 0 $btnPaddingSmall;
	}

	// Types
	&--secondary {
		background-color: $cButtonSecondary;
		box-shadow: 0px 4px 8px rgba($cButtonSecondary, 0.25);
		@include link-active-styles {
			background-color: $cButtonPrimary;
			box-shadow: 0px 4px 10px rgba($cButtonPrimary, 0.25);
		}
	}
	&--tertiary {
		background-color: $cButtonTertiary;
		box-shadow: 0px 4px 8px rgba($cButtonTertiary, 0.25);
		color: $cCanvas;
		@include link-active-styles {
			background-color: $cTealDark;
			box-shadow: 0px 4px 10px rgba($cTealDark, 0.25);
		}
	}
	&--reverse {
		background-color: $cCanvas;
		box-shadow: 0px 4px 8px rgba($cSlateGrey, 0.25);
		@include link-active-styles {
			background-color: $cButtonSecondary;
			box-shadow: 0px 4px 10px rgba($cButtonSecondary, 0.25);
		}
	}
	&--brush {
		background-color: transparent;
		box-shadow: none;
		background-image: url(/assets/img/svg/button-stroke.svg);
		background-position: center;
		background-size: contain;
		padding: 0 60px;
		line-height: $btnDefault;
		@include link-active-styles {
			background-color: transparent;
			box-shadow: none;
			transform: translateY(0);
		}
	}
	&--utility {
		background-color: $cCanvasMedium;
		border-radius: 1.5em;
		padding: 0.75em 1em 0.75em 0.75em;
		box-shadow: none;
		font-family: $fontBrand;
		svg {
			width: 15px;
			height: 15px;
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
		}
		&-simple {
			background-color: transparent;
			border-radius: 0;
			padding: 0;
			@include link-active-styles {
				background-color: transparent;
				box-shadow: none;
				transform: translateY(0);
			}
		}
		&-minor {
			font-size: rem($typeS);
			padding: 0.5em 0.75em 0.5em 0.75em;
			svg {
				width: 12px;
				height: 12px;
				transform: translateY(-3px);
			}
		}
	}
	&--icon {
		padding-right: $btnPadding*3;
		text-align: left;
		svg {
			position: absolute;
			top: 50%;
			right: math.div($btnPadding,1.25);;
			transform: translateY(-50%);
			transition-duration: 0.3s;
			transition-property: transform;
			transition-timing-function: ease-out;
			transform-origin: top;
			height: 13px;
			width: 13px;
			display: block;
			fill: $cSlateGrey;
		}
		@include link-active-styles {
			svg {
				animation-name: hvr-icon-pop;
				animation-duration: 0.3s;
				animation-timing-function: linear;
				animation-iteration-count: 1;
			}
		}
	}
	&--disabled {
		cursor: not-allowed;
		opacity: 0.3;
		filter: grayscale(100%);
		&:focus {
			outline: none;
			text-decoration: none;
		}
		@include link-active-styles {
			text-decoration: none;
		}
	}
	&--block {
		display: block;
	}
	&--a11y {
		position: relative;
		overflow: hidden;
		span {
			top: -9999em;
			position: absolute;
			display: inline-block;
		}
		&:after {
			content: attr(title);
			display: inline-block;
			color: inherit;
		}
	}
	&--no-js-hide {
		html.no-js & {
			display: none;
		}
	}
	&--js-hide {
		html.js & {
			display: none;
		}
	}
}


.button-group {
	margin-top: math.div($bufferSpaceS,2);
	.button, a {
		display: block;
		margin-top: 10px;
		@include respond-to($vpB) {
			display: inline-block;
			margin-right: 10px;
		}
	}
	.typebase & {
		margin-bottom: $bufferSpaceS;
	}
	&--spaced {
		margin-top: $bufferSpaceM;
	}
}
