@use 'sass:math';

.form {
	position: relative;
	background-color: $cCanvas;
	padding: $bufferSpaceM;
	border-radius: $componentRadius;
	&--spaced {
		margin: $bufferSpaceM 0;
	}
}

.fieldset {
	border: none;
	padding: 0;
	margin-bottom: $bufferSpaceM;
	&:last-child {
		margin-bottom: 0;
	}
	legend {
		@extend .heading;
		font-size: rem($typeL);
		margin-bottom: 12px;
		position: relative;
	}
}

// Labels
.form-label {
	font-size: rem($typeS);
	color: $cLabel;
	margin-bottom: 12px;
	position: relative;
	&--hidden {
		@include a11yhide;
	}
	svg {
		width: 15px;
		height: 15px;
		position: relative;
		margin: -2px 0 0 3px;
	}
}

// Form field wrapper
.form-field {
	position: relative;
	@include pie-clearfix;
	&--spaced {
		padding-top: math.div($bufferSpaceS,1.5);
	}
	&--error {
		.form-item {
			border-color: $cError;
			&:focus {
				border-color: $cError;
			}
		}
		.form-label {
			color: $cError;
		}
	}
	&--select-button {
		@include pie-clearfix;
		.form-field__select {
			float: left;
			position: relative;
			select {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		.button {
			float: left;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.hint {
		font-size: rem($typeS);
		padding-top: 10px;
		clear: left;
		display: block;
	}
	&__error__message {
		color: $cError;
		display: none;
		font-size: rem($typeXS);
		padding-top: 6px;
		clear: left;
		&--visible {
			display: block;
		}
	}
	&__pair {
		padding-top: math.div($bufferSpaceS,1.5);
		@include respond-to($vpC) {
			padding-top: 0;
			width: 49.5%;
			margin-left: 1%;
			display: inline-block;
			margin-bottom: 0;
			float: left;
			&:first-child {
				margin-left: 0;
			}
		}
	}
	&__triplet {
		padding-top: math.div($bufferSpaceS,1.5);
		@include respond-to($vpC) {
			padding-top: 0;
			width: 32.5%;
			margin-bottom: 0;
			margin-left: 1.25%;
			display: inline-block;
			margin-bottom: 0;
			float: left;
			&:first-child {
				margin-left: 0;
			}
		}
	}
	.hint {
		font-size: rem($typeXS);
		padding-top: 6px;
		clear: left;
		display: block;
	}
}

// Standard form elements
.form-item {
	padding: 10px;
	display: block;
	border: $strokeWidth solid $cCanvasMedium;
	appearance: none;
	background-color: #fff;
	height: $formItem;
	width: 100%;
	font-size: 15px; // Stop IOS doing the zoom thing, the Git!
	border-radius: $btnRadius;
	margin-top: 6px;
	@extend %shadowForm;
	&::focus-inner {
		border: 0;
		padding: 0;
	}
	&:focus {
		outline: none;
		border-color: $cCanvasDark;
	}
	&--small {
		height: math.div($formItem,1.25);
	}
}

// Turn off spinners for number input field, as flipping ugly
.form-item[type=number]::-webkit-inner-spin-button,
.form-item[type=number]::-webkit-outer-spin-button {
	appearance: none;
}

// Min height to text area
textarea.form-item {
	min-height: 200px;
}

.form-meta {
	font-size: rem($typeS);
	margin-top: $bufferSpaceS;
}

// Radio and Checkboxes baseline
// -----------------------

.form-field__radio-buttons,
.form-field__checkboxes {
	margin-top: 6px;
	label {
		position: relative;
		top: 0;
		vertical-align: middle;
		&:after {
			clear: left;
			content: "";
			display: block;
			height: 0;
		}
	}
	input {
		float: left;
		margin-right: 12px;
		vertical-align: middle;
		&:after {
			clear: left;
			content: "";
			display: block;
			height: 0;
		}
	}
	input[type="radio"],
	input[type="checkbox"] {
		width: 16px;
		height: 16px;
		position: relative;
		top: 4px;
	}
	input[type="radio"] {
		width: 16px;
		height: 16px;
		position: relative;
		top: 3px;
	}
}

// Select baseline
// -----------------------

.form-field__select {
	background-color: #fff;
	margin-top: 6px;
	select, .select2-container {
		width: 100%;
		display: block;
		height: $formItem;
		padding: 0px 5px 0 5px !important;
		vertical-align: middle;
		border: $strokeWidth solid $cCanvasMedium;
		appearance: none;
		-moz-appearance: window; // Hides firefox native select arrow
		background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>") #fff no-repeat right 47%;
		background-origin: content-box;
		background-size: 22px 19px;
		background-position: 98% 50%;
		border-radius: 0;
		font-size: 15px;
		line-height: 18px;
		border-radius: $btnRadius;
		@extend %shadowForm;
		&:hover, &:focus {
			border-color: $cCanvasDark;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
		&::-ms-expand {
			display: none;
		}
		.formfieldError &, .form-field--error & {
			border-color: $cError;
		}
		option {
			max-width: 90%;
		}
	}
	&--small {
		height: math.div($formItem,1.25);
		select {
			height: math.div($formItem,1.25);
		}
	}
	.select2-container {
		width: 100% !important;
		font-size: rem($typeS);
		padding: 0;
		height: auto !important;
		min-height: $formItem;
	}
	.select2-selection--multiple {
		min-height: $formItem !important;
		background-color: transparent !important;
		border: none !important;
		padding-top: 5px !important;
	}
	.select2-selection__choice {
		display: inline-flex !important;
		font-size: rem($typeS);
		margin-top: 2px !important;
		margin-left: 2px !important;
		background-color: $cCanvasLight !important;
		border: 1px solid $cCanvasMedium !important;
	}
	.select2-selection__choice__remove {
		font-size: 20px !important;
		color: $cSlateGrey !important;
		border-color: $cCanvasMedium !important;
		padding: 4px 4px !important;
		&:hover {
			background-color: $cCanvasDark !important;
		}
	}
	.select2-selection__choice__display {
		transform: translateY(-2px);
		display: inline-block;
		padding: 5px !important;
		padding-bottom: 0 !important;
	}
}

.select2-container .select2-search--inline .select2-search__field {
	margin-top: 8px !important;
}

.select2-results__option {
	font-size: rem($typeS) !important;
}
.select2-results__option--highlighted.select2-results__option--selectable {
	background-color: $cMustard !important;
	color: $cHeading !important;
}
.select2-container--default .select2-results__option--selected {
	background-color: $cCanvasMedium !important;
}

.grecaptcha-badge { visibility: hidden; }

// Date input
.form-field__date {
	font-size: rem($typeMe);
	input {
		width: auto;
	}
	.form-field__triplet & {
		input {
			width: 100%;
		}
	}
}
