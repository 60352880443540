@use 'sass:math';

// Type scale
// ------------------------------------------------------------------

$scaleRoot: 				10;
$lineheightRoot: 			18;
$lineheightBase: 			math.div($lineheightRoot,$scaleRoot);

$typeXXL: 					$scaleRoot*4.8;
$typeXL: 					$scaleRoot*3.6;
$typeLLL: 					$scaleRoot*3.2;
$typeLL: 					$scaleRoot*2.8;
$typeL: 					$scaleRoot*2.4;
$typeM:						$scaleRoot*2.1;
$typeMe:					$scaleRoot*1.6;		// Me = 'median'. Generally the standard font size for the page
$typeS: 					$scaleRoot*1.4;
$typeXS: 					$scaleRoot*1.2;

// Declaritives
$typeDefault: 				$typeMe;
$typeDefaultL: 				$scaleRoot*1.8;


// Functions and mixins
// ------------------------------------------------------------------

// Make pixel values into REMS
@function rem($pixels){
	@return math.div($pixels,$scaleRoot) + rem; // Usage = font-size: rem(xx);
}

// Tighten leading
@mixin leading_adjust($gapAdjust) {
	line-height:  $lineheightBase - (math.div($gapAdjust,$scaleRoot));
	margin-bottom: rem($lineheightBase + $gapAdjust);
}
