// Sidebar frame
.side-bar {
	&__main {
		margin-bottom: $bufferSpaceM;
	}
	@include respond-to($vpC) {
		@include gridCalc('true');
		&__main {
			margin-bottom: 0;
			width: 65%;
			float: left;
			@supports (display: grid) {
				@include gridPosition(1, 8);
				@include gridRow(1);
				width: auto;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 65%;
			}
		}
		&__main-wide {
			margin-bottom: 0;
			width: 68%;
			float: left;
			@supports (display: grid) {
				@include gridPosition(1, 8);
				@include gridRow(1);
				width: auto;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 68%;
			}
		}
		&__aside {
			width: 30%;
			float: right;
			@supports (display: grid) {
				@include gridPosition(10, 3);
				@include gridRow(1);
				width: auto;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 30%;
			}
		}

	}
}

// 1 column grid
.grid-1up {
	@include gridCalcRepeat(1);
	&--trailer {
		margin-bottom: $bufferSpaceS;
	}
	> div, > a {
		@include respond-to($vpC) {
			width: 100%;
			margin-bottom: 2em;
			@supports (display: grid) {
				width: auto;
				margin-bottom: 0;
			}
			@supports (display: -ms-grid) {
				width: 100%;
			}
		}
	}
}

// 2 column grid
.grid-2up {
	@include gridCalcRepeat(1);
	@include respond-to($vpC) {
		@include gridCalcRepeat(2);
	}
	&--trailer {
		margin-bottom: $bufferSpaceM;
	}
	@include respond-to($vpC) {
		@include gridCalcRepeat(2);
		&--narrow {
			@include gridCalcRepeat(2, 20px, 20px);
		}
	}
	> div, > a {
		@include respond-to($vpC) {
			width: 49%;
			margin-left: 2%;
			float: left;
			margin-bottom: 2em;
			&:nth-child(2n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				margin-bottom: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 49%;
				float: left;
			}
		}
	}
}

// 2 column grid
.grid-2up--small {
	@include gridCalcRepeat(1);
	@include respond-to($vpB) {
		@include gridCalcRepeat(2);
	}
	&--trailer {
		margin-bottom: $bufferSpaceM;
	}
	@include respond-to($vpB) {
		@include gridCalcRepeat(2);
		&--narrow {
			@include gridCalcRepeat(2, 20px, 20px);
		}
	}
	> div, > a {
		@include respond-to($vpB) {
			width: 49%;
			margin-left: 2%;
			float: left;
			margin-bottom: 2em;
			&:nth-child(2n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				margin-bottom: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 49%;
				float: left;
			}
		}
	}
}

.grid-2up-offset {
	margin-bottom: $bufferSpaceS;
	@include respond-to($vpC) {
		@include gridCalc('true');
		&__main {
			@include gridPosition(1, 7);
			@include gridRow(1);
		}
		&__aside {
			@include gridPosition(8, 5);
			@include gridRow(1);
		}
	}
}

// 3 column grid
.grid-3up {
	@include gridCalcRepeat(1);
	&--narrow {
		@include gridCalcRepeat(1, 50px, 20px);
	}
	@include respond-to($vpB) {
		@include gridCalcRepeat(2);
		&--narrow {
			@include gridCalcRepeat(2, 50px, 20px);
		}
	}
	@include respond-to($vpC) {
		@include gridCalcRepeat(3);
		&--narrow {
			@include gridCalcRepeat(3, 20px, 20px);
		}
	}
	&--trailer {
		margin-bottom: $bufferSpaceM;
	}
	> div, > a {
		@include respond-to($vpC) {
			width: 32%;
			margin-left: 2%;
			float: left;
			&:nth-child(3n+1) {
				margin-left: 0;
				clear: left;
			}
			@supports (display: grid) {
				width: auto;
				margin-left: 0;
				float: none;
			}
			@supports (display: -ms-grid) {
				width: 33%;
				float: left;
			}
		}
	}
}

// 4 column grid
.grid-4up {
	&--trailer {
		margin-bottom: $bufferSpaceM;
	}
	&--narrow {
		@include gridCalcRepeat(1, 20px, 20px);
	}
	@include respond-to($vpB) {
		@include gridCalcRepeat(2);
		&--narrow {
			@include gridCalcRepeat(2, 20px, 20px);
		}
	}
	@include respond-to($vpC) {
		@include gridCalcRepeat(4);
		&--narrow {
			@include gridCalcRepeat(4, 20px, 20px);
		}
		> div, > a {
			@include respond-to($vpC) {
				width: 23.5%;
				margin-left: 2%;
				float: left;
				&:nth-child(4n+1) {
					margin-left: 0;
					clear: left;
				}
				@supports (display: grid) {
					width: auto;
					margin-left: 0;
					float: none;
				}
				@supports (display: -ms-grid) {
					width: 23.5%;
					float: left;
				}
			}
		}
	}
	&--paddless {
		@include gridCalcRepeat(2, 0, 0);
		@include respond-to($vpC) {
			@include gridCalcRepeat(4, 0, 0);
		}
	}
}

// 5 column grid
.grid-5up {
	> div, > a {
		margin-bottom: $bufferSpaceS;
	}
	@include respond-to($vpB) {
		@include gridCalcRepeat(3);
		grid-column-gap: 10px !important;
		> div, > a {
			margin-bottom: 0;
		}
	}
	@include respond-to($vpC) {
		@include gridCalcRepeat(5);
		> div, > a {
			@include respond-to($vpC) {
				width: 18.4%;
				margin-left: 2%;
				float: left;
				&:nth-child(5n+1) {
					margin-left: 0;
					clear: left;
				}
				@supports (display: grid) {
					width: auto;
					margin-left: 0;
					float: none;
				}
				@supports (display: -ms-grid) {
					width: 18.4%;
					float: left;
				}
			}
		}
	}
}
