
.row {
  @include pie-clearfix;
  padding-left: $bufferSpaceS;
  padding-right: $bufferSpaceS;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  min-width: auto;
  max-width: $vpMax;
  &--narrow {
    max-width: $vpC;
  }
  &--leader {
    margin-top: $bufferSpaceM;
  }
}
