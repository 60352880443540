.drawer {
    +.drawer {
        margin-top: $bufferSpaceS;
    }
}

.drawer[open] {
    .drawer__title {
        background-color: $cCanvasMedium;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .drawer__title::before {
        transform: rotate(0deg);
        left: 17px;
        top: 19px;
        background-image: url(/assets/icons/source/drop.svg);
    }
    &.drawer--alt .drawer__title {
    	background-color: transparent;
    	&::before {
	        left: auto;
	        right: -14px;
	        top: 5px;
	    }
    }
}

.drawer__title {
    position: relative;
    font-size: rem($typeM);
    font-weight: $fontBold;
    padding: $bufferSpaceS*.5;
    padding-left: $bufferSpaceM*1.2;
    background-color: #fff;
    transition: all, ease-in-out, 0.1s;
    cursor: pointer;
    border-radius: 5px;
    list-style: none;
    .drawer--alt & {
    	background-color: transparent;
    	font-size: inherit;
    	font-weight: $fontNormal;
    	padding: 0!important;
    }
    &::-webkit-details-marker {
        display: none;
    }
    &:before {
        content: "";
        background-image: url(/assets/icons/source/drop-turquoise.svg);
        transform: rotate(-90deg);
        width: 15px;
        height: 15px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: $bufferSpaceM;
        display: block;
        position: absolute;
        left: 15px;
        top: 21px;
        transition: all, ease-in-out, 0.1s;
        .drawer--alt & {
	    	left: auto;
	    	right: -12px;
	    	top: 6px;
	    	margin-right: $bufferSpaceS;
	    }
    }
}

.drawer__content {
    padding: $bufferSpaceM;
    background-color: #fff;
    @include respond-to($vpC) {
        padding: $bufferSpaceS $bufferSpaceS $bufferSpaceS $bufferSpaceM*1.2;
    }
    &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .typebase {
        h1,h2,h3,h4,h5,h6,p,ol,ul,li {
            color: $cHeading;
        }
    }
    .drawer--alt & {
    	background-color: transparent;
    	padding-left: 0;
    	.typebase p {
    		font-size: 14px;
    	}
    }
}

.drawer__content__heading {
    @extend .heading;
    font-size: rem($typeL);
    margin-bottom: $bufferSpaceM;
}