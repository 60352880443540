.filter {
    background-color: $cCanvas;
    padding: $bufferSpaceS;
    border-radius: $componentRadius;
    @extend %shadowStraightLarge;
    @include respond-to($vpB) {
        padding: $bufferSpaceS $bufferSpaceM;
    }
}

.filter__heading {
    @extend .heading;
    position: relative;
    font-size: rem($typeL);
    outline: none;
    display: block;
    width: 100%;
    cursor: pointer;
    svg {
        position: absolute;
        top: 6px;
        right: 0;
        width: 15px;
        height: 15px;
        transition: all, ease-in-out, 0.3s;
    }
    &--open {
        svg {
            transform: rotate(180deg);
        }
    }
    @include respond-to($vpC) {
        margin-bottom: $bufferSpaceS;
        cursor: default;
        svg {
            display: none;
        }
    }
    .filter--alt & {
        @include respond-to($vpC) {
            margin-bottom: 0;
            cursor: pointer;
            svg {
                display: block;
            }
        }
    }
}

.filter__reveal {
    overflow: hidden;
    @include respond-to-max($vpC) {
        html.js & {
            transition: all, ease-in-out, 0.25s;
            max-height: 0;
        }
        &--open {
            html.js & {
                margin-top: $bufferSpaceS;
                max-height: 4000px;
            }
        }
    }
    .filter--alt & {
        @include respond-to($vpC) {
            html.js & {
                transition: all, ease-in-out, 0.25s;
                max-height: 0;
            }
            &--open {
                html.js & {
                    margin-top: $bufferSpaceS;
                    max-height: 800px;
                }
            }
        }
    }
}

.filter__form {
    @include gridCalcRepeat(1,20px,20px);
    grid-row-gap: 20px;
    @include respond-to($vpC) {
        @include gridCalcRepeat(6);
        @supports (display: grid) {
            grid-row-gap: 20px;
            grid-gap: 20px;
        }
        .form-field {
            width: 14.1%;
            margin-left: 1%;
            margin-bottom: 1em;
            float: left;
            .button {
                margin-top: 30px;
                &.button--utility {
                    margin-top: 10px;
                }
            }
            @supports (display: grid) {
                width: auto;
                margin-left: 0;
                margin-bottom: 0;
                float: none;
                &:last-child {
                    grid-area: 1 / 6 / 3 / 7;
                }
                &:first-child {
                    grid-area: 1 / 1 / 3 / 1;
                }
            }
            @supports (display: -ms-grid) {
                width: 14.1%;
                float: left;
            }
        }
        @supports (display: grid) {
            .form-field:nth-last-child(n+7):nth-last-child(-n+7):first-child, 
            .form-field:nth-last-child(n+7):nth-last-child(-n+7):first-child ~ .form-field {
                &:nth-last-child(2) {
                    grid-column: span 4;
                }
            }
            .form-field:nth-last-child(n+8):nth-last-child(-n+8):first-child, 
            .form-field:nth-last-child(n+8):nth-last-child(-n+8):first-child ~ .form-field {
                &:nth-last-child(2) {
                    grid-column: span 3;
                }
            }
            .form-field:nth-last-child(n+9):nth-last-child(-n+9):first-child, 
            .form-field:nth-last-child(n+9):nth-last-child(-n+9):first-child ~ .form-field {
                &:nth-last-child(2) {
                    grid-column: span 2;
                }
            }
        }
    }
    &--alt {
        @supports (display: grid) {
            grid-gap: 20px;
        }
        @include respond-to($vpC) {
            @include gridCalcRepeat(5);
            @supports (display: grid) {
                grid-gap: 20px;
            }
            .form-field {
                width: 19.2%;
                margin-left: 1%;
                margin-bottom: 1em;
                float: left;
                .button {
                    margin-top: 30px;
                    &.button--utility {
                        margin-top: 10px;
                        display: block;
                        text-align: left;
                    }
                }
                @supports (display: grid) {
                    width: auto;
                    margin-left: 0;
                    margin-bottom: 0;
                    float: none;
                    &:first-child {
                        grid-area: 1 / 1 / 3 / 2;
                    }
                    &:last-child {
                        grid-area: 1 / 5 / 3 / 6;
                    }
                }
                @supports (display: -ms-grid) {
                    width: 19.2%;
                    float: left;
                }
            }
            @supports (display: grid) {
                .form-field:nth-last-child(n+6):nth-last-child(-n+6):first-child, 
                .form-field:nth-last-child(n+6):nth-last-child(-n+6):first-child ~ .form-field {
                    &:nth-last-child(2) {
                        grid-column: span 3;
                    }
                }
                .form-field:nth-last-child(n+7):nth-last-child(-n+7):first-child, 
                .form-field:nth-last-child(n+7):nth-last-child(-n+7):first-child ~ .form-field {
                    &:nth-last-child(2) {
                        grid-column: span 2;
                    }
                }
                .form-field:nth-last-child(n+8):nth-last-child(-n+8):first-child, 
                .form-field:nth-last-child(n+8):nth-last-child(-n+8):first-child ~ .form-field {
                    &:nth-last-child(2) {
                        grid-column: span 1;
                    }
                }
            }
        }
    }
    &--minor {
        @supports (display: grid) {
            grid-gap: 20px;
        }
        .button {
            &.button--utility {
                margin-left: 10px;
            }
        }
        @include respond-to($vpC) {
            @include gridCalcRepeat(3);
            @supports (display: grid) {
                grid-gap: 20px;
            }
            .form-field {
                width: 32%;
                margin-left: 1%;
                margin-bottom: 1em;
                float: left;
                .button {
                    margin-top: 30px;
                    &.button--utility {
                        display: inline-block;
                        text-align: left;
                        margin-top: 25px;
                    }
                }
                @supports (display: grid) {
                    width: auto;
                    margin-left: 0;
                    margin-bottom: 0;
                    float: none;
                    &:last-child {
                        grid-area: 1 / 3 / 3 / 3;
                    }
                }
                @supports (display: -ms-grid) {
                    width: 32%;
                    float: left;
                }
            }
        }
    }
    &--research {
        .button {
            &.button--utility {
                margin-left: 10px;
            }
        }
        @include respond-to($vpC) {
            @include gridCalcRepeat(12);
            @supports (display: grid) {
                grid-gap: 20px;
            }
            .form-field {
                width: 19%;
                margin-left: 1%;
                margin-bottom: 1em;
                float: left;
                .button {
                    margin-top: 30px;
                    &.button--utility {
                        display: inline-block;
                        text-align: left;
                        margin-top: 25px;
                    }
                }
                @supports (display: grid) {
                    width: auto;
                    margin-left: 0;
                    margin-bottom: 0;
                    float: none;
                    grid-column: span 3;
                    &:first-child {
	                    grid-area: 1 / 1 / 4 / 3;
	                }
		            &:nth-last-child(n+7):nth-last-child(-n+7):first-child,
		            &:nth-last-child(n+7):nth-last-child(-n+7):first-child ~ .form-field {
		                &:nth-last-child(3) {
		                    grid-column: span 5;
		                }
		            }
                    &:last-child {
	                    grid-area: 1 / 12 / 2 / 12;
	                }
                }
                @supports (display: -ms-grid) {
                    width: 19%;
                    float: left;
                }
            }
        }
    }
}