.duo {
    position: relative;
    margin-bottom: $bufferSpaceL;
    &:last-of-type {
        margin-bottom: 0;
    }
    @include respond-to($vpC) {
        display: flex;
        align-items: center;
        justify-content: left;
    }
}

.duo__media {
    img {
        @extend .max-width;
        border-radius: $componentRadius;
    }
    @include respond-to($vpC) {
        width: 50%;
        .duo--reverse & {
            margin-left: auto;
        }
    }
}

.duo__content {
    position: relative;
    z-index: 2;
    background-color: $cCanvas;
    padding: $bufferSpaceS;
    border-radius: $componentRadius;
    @extend %shadowStraightLarge;
    max-width: 90%;
    margin-top: -$bufferSpaceM;
    margin-left: auto;
    margin-right: auto;
    @include respond-to($vpB) {
        padding: $bufferSpaceM;
    }
    @include respond-to($vpC) {
        width: 60%;
        position: absolute;
        right: 0;
        max-width: 100%;
        margin: 0;
        .duo--reverse & {
            left: 0;
            right: auto;
        }
    }
    @include respond-to($vpD) {
        padding: $bufferSpaceM*1.5;
    }
}

.duo__heading {
    @extend .heading;
    line-height: 1;
    font-size: rem($typeXL);
    margin-bottom: $bufferSpaceS;
}

.duo__copy {
    font-size: rem($typeDefault);
    margin-bottom: $bufferSpaceS;
}