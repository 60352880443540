@use 'sass:math';

.card {
    position: relative;
    background-color: $cCanvas;
    border-radius: $componentRadius;
    overflow: hidden;
    .section--highlight & {
        border: 1px solid $cBorder;
    }
    .grid__col & {
        margin-top: $bufferSpaceS;
        &:first-child {
            margin-top: 0;
        }
    }
    &--horizontal {
        display: flex;
        min-height: 200px;
    }
    &--featured {
        display: block;
        position: relative;
    }
    &--alt {
        background-color: transparent;
    }
}

a.card {
    border-bottom: none;
    transition: all, ease-in-out, 0.3s;
    @include link-active-styles {
        @extend %shadowStraightLarge;
        .card__heading {
            color: $cTealMedium;
        }
        .button {
            background-color: $cButtonSecondary;
            box-shadow: 0px 4px 10px rgba($cButtonSecondary, 0.25);
        }
    }
    &--featured {
        @include link-active-styles {
            .card__content {
                background-color: rgba($cSlateGrey, 0.85);
            }
            .card__media {
                transform: scale(1.2);
            }
            .card__heading {
                color: $cCanvas;
            }
        }
    }
}

.card__media {
    overflow: hidden;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    display: none;
    background-color: $cTealLight;
    min-height: 250px;
    @include respond-to($vpC) {
        display: block;
    }
    .card--horizontal & {
        @include respond-to($vpB) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30%;
            min-height: auto;
        }
    }
    &--logo {
        background-color: #fff;
        background-size: 60%;
        background-position: center;
        border: 1px solid $cBorder;
        margin: $bufferSpaceS;
        margin-bottom: 0;
        min-height: 180px;
        &:hover {
            border-bottom: 1px solid $cBorder;
        }
        @include respond-to($vpB) {
            margin: $bufferSpaceM;
            margin-bottom: 0;
        }
        .card--minor & {
            min-height: 125px;
            margin: $bufferSpaceS;
            margin-bottom: 0;
            @include respond-to($vpB) {
                margin: $bufferSpaceS;
                margin-bottom: 0;
            }
        }
        .card--horizontal & {
            margin-bottom: $bufferSpaceS;
            margin-right: 0;
            @include respond-to($vpB) {
                margin: $bufferSpaceM;
                margin-bottom: $bufferSpaceM;
                margin-right: 0;
            }
        }
        &-small {
            display: block;
            min-height: 100px;
            align-self: flex-start;
            @include respond-to($vpB) {
              min-height: 70px;
            }
        }
    }
    .card--featured & {
        position: relative;
        display: block;
        min-height: 540px;
        transition: all, ease-in-out, 0.3s;
    }
    .card--chart & {
    	position: relative;
        display: block;
    	background-color: transparent;
    }
    img,
    svg {
        @extend .max-width;
    }
}

a.card__media {
    border-bottom: none;
    &--logo {
        border-bottom: 1px solid $cBorder;
    }
    @include link-active-styles {
        border-bottom: none;
        &--logo {
            border-bottom: 1px solid $cBorder;
        }
    }
}

.card__event {
    text-align: center;
    max-width: 80%;
    color: $cCanvas;
    @extend .heading;
    .hero__aside & {
        max-width: 100%;
        background-color: $cTealLight;
        padding: $bufferSpaceM 0;
        border-radius: $componentRadius;
    }
}

.card__event-day {
    font-size: rem($typeXXL*1.5);
    line-height: 1;
    display: block;
}

.card__event-month, .card__event-year {
    font-size: rem($typeXL);
    line-height: 1;
    display: block;
}

.card__title {
    padding: $bufferSpaceS $bufferSpaceS 0 $bufferSpaceS;
    z-index: 5;
    position: relative;
    @include respond-to($vpB) {
        padding: $bufferSpaceM $bufferSpaceM 0 $bufferSpaceM;
    }
    .card__media {
        margin: 0;
        margin-right: $bufferSpaceS;
        @include respond-to($vpB) {
          margin-right: $bufferSpaceS;
        }
    }
    .card__heading {
        margin-bottom: 0;
        .card--chart & {
        	font-size: rem($typeLLL);
        }
    }
    &--member {
        @supports (display: grid) {
        		display: grid;
        		grid-template-columns: 1fr 2fr;
      	}
      	@supports (display: -ms-grid) {
      		  display: block;
      	}
    }
}

.card__content {
    padding: $bufferSpaceS;
    z-index: 5;
    position: relative;
    @include respond-to($vpB) {
        padding: $bufferSpaceM;
    }
    .card--minor & {
        @include respond-to($vpB) {
            padding: $bufferSpaceS;
        }
    }
    .card--horizontal & {
        width: 100%;
        flex: 1;
        @include respond-to($vpC) {
            width: 70%;
        }
    }
    .card--featured & {
        background-color: rgba($cSlateGrey, 0.75);
        position: absolute;
        bottom: 0;
        transition: all, ease-in-out, 0.3s;
        width: 100%;
    }
    .card--alt & {
        padding: 0;
    }
}

.card__video {
	position: relative;
    html.no-js & {
        display: none;
    }
}

.card__blockquote {
    @extend .blockquote;
    margin: 0;
    border-left: none;
    padding-left: 0;
    svg {
        left: 0;
    }
}

.card__flag {
    font-size: rem($typeS);
    background-color: $cJade;
    padding: 0.5em 1em;
    margin-bottom: math.div($bufferSpaceS,2);
    display: inline-block;
    line-height: 1.3;
    .card--horizontal & {
        @include respond-to($vpC) {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .card--featured & {
        position: absolute;
        top: 0;
        left: 0;
    }
    &--top-right {
    	position: absolute;
        top: 0;
        right: 0;
    }
}

.card__heading {
    @extend .heading;
    font-size: rem($typeL);
    margin-bottom: $bufferSpaceS;
    line-height: 1.3;
    transition: all, ease-in-out, 0.3s;
    .card--featured & {
        color: $cCanvas;
    }
}

.card__excerpt {
    font-size: rem($typeDefault);
    margin-bottom: $bufferSpaceS;
    .card__title & {
    	margin-bottom: 0;
    }
}

.card__meta {
    position: relative;
    font-size: rem($typeDefault);
    list-style: none;
    margin-bottom: $bufferSpaceS;
    li {
        margin-bottom: 5px;
        svg {
            width: 17px;
            height: 17px;
            vertical-align: text-top;
            margin-right: 10px;
            display: inline-block;
            fill: $cTealMedium;
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
    .card--featured & {
        color: $cCanvas;
        li svg {
            fill: $cCanvas;
        }
    }
}

.card__sublist {
    margin-top: $bufferSpaceS;
    &--commitment {
      margin-bottom: $bufferSpaceM;
    }
}

.card__sublist-heading {
    font-size: rem($typeXS);
    text-transform: uppercase;
    letter-spacing: 1px;
}

.card__sublist-list {
    font-size: rem($typeDefault);
    list-style: none;
    li {
        display: inline-block;
        a {
            border-bottom: 0;
            text-decoration: underline;
        }
        &:after {
            content: ",";
        }
        &:last-child {
            &:after {
                content: none;
            }
        }
    }
}

.card--ribbon {
	overflow: visible;
	&:after {
		position: absolute;
		z-index: 4;
	}
}

.card--ribbon-1:after {
	content : url(../img/svg/campaign/ribbon-01.svg);
	top: -28px;
	left: -39px;
}
.card--ribbon-2:after {
	content : url(../img/svg/campaign/ribbon-05.svg);
	top: -20px;
	right: -20px;
}
.card--ribbon-3:after {
	content : url(../img/svg/campaign/ribbon-03.svg);
	bottom: -43px;
	left: 100px;
}
.card--ribbon-4:after {
	content : url(../img/svg/campaign/ribbon-07.svg);
	top: -40px;
	left: -44px;
}
.card--ribbon-5:after {
	content : url(../img/svg/campaign/ribbon-02.svg);
	bottom: -20px;
	left: -37px;
}
.card--ribbon-6:after {
	content : url(../img/svg/campaign/ribbon-12.svg);
	right: -60px;
	top: -25px;
}
.card--ribbon-7:after {
	content : url(../img/svg/campaign/ribbon-06.svg);
	bottom: -30px;
	left: -46px;
}
.card--ribbon-8:after {
	content : url(../img/svg/campaign/ribbon-04.svg);
	bottom: -50px;
	right: -20px;
	transform: rotate(10deg);
}
.card--ribbon-9:after {
	content : url(../img/svg/campaign/ribbon-11.svg);
	right: -20px;
	bottom: -30px;
}
.card--ribbon-10:after {
	content : url(../img/svg/campaign/ribbon-10.svg);
	left: -20px;
	bottom: -10px;
	transform: rotate(40deg);
}
.card--ribbon-11:after {
	content : url(../img/svg/campaign/ribbon-13.svg);
	right: -100px;
	bottom: -30px;
}
.card--ribbon-12:after {
	content : url(../img/svg/campaign/ribbon-08.svg);
	bottom: -40px;
	right: -20px;
}
.card--ribbon-13:after {
	content : url(../img/svg/campaign/ribbon-15.svg);
	left: -21px;
	bottom: -38px;
}
.card--ribbon-14:after {
	content : url(../img/svg/campaign/ribbon-14.svg);
	left: 20px;
	top: -32px;
}
.card--ribbon-15:after {
	content : url(../img/svg/campaign/ribbon-16.svg);
	right: -20px;
	bottom: -41px;
}
