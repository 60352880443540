@use 'sass:math';

.billboard {
    &__aside {
        margin-bottom: $bufferSpaceS;
    }
    @include respond-to($vpC) {
        display: flex;
        align-items: center;
        justify-content: left;
        &__aside {
            margin-bottom: 0;
            width: 40%;
            .billboard--alt & {
                width: 60%;
            }
            .billboard--half & {
                width: 50%;
            }
        }
        &__main {
            width: 60%;
            padding-left: $bufferSpaceM;
            .billboard--alt & {
                width: 40%;
            }
            .billboard--half & {
                width: 50%;
            }
        }
    }
}

.billboard__media {
    img {
        @extend .max-width;
    }
    &--video {
        position: relative;
        @include ratio(16 9);
        object,embed,iframe  {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }   
    }
}

.billboard__header {
    @extend %pie-clearfix;
    .m-funding {
        margin-bottom: math.div($bufferSpaceS,2);
    }
    @include respond-to($vpC) {
        .billboard__heading {
            float: left;
            width: 70%;
        }
        .m-funding {
            float: right;
            margin-bottom: 0;
        }
    }
}

.billboard__heading {
    @extend .heading;
    font-size: rem($typeXXL);
    line-height: 1;
    margin-bottom: $bufferSpaceS;
    i {
        color: $cMustard;
        font-style: normal;
    }
}

.billboard__subheading {
    @extend .heading;
    font-size: rem($typeL);
    line-height: 1;
    margin-top: $bufferSpaceS;
}

.billboard__footer {
    overflow: hidden;
    margin-top: $bufferSpaceS;
    @include respond-to($vpC) {
        .button {
            display: inline-block;
        }
        .m-share {
            display: inline-block;
            margin-left: $bufferSpaceM;
            transform: translateY(15px);
        }
    }
}