.hero {
	@include pie-clearfix;
	position: relative;
	z-index: 1;
	background-color:  $cCanvasMedium;
	padding: $bufferSpaceL 0 $bufferSpaceM 0;
	&__main {
		position: relative;
		z-index: 1;
	}
	&__aside {
		position: relative;
		z-index: 1;
		display: block;
		@include respond-to-max($vpC) {
			margin-top: $bufferSpaceS;
		}
	}
	.button {
		position: relative;
		z-index: 5;
	}
	&--large {
		padding: $bufferSpaceL 0;
	}
	&--image {
		background-size: cover;
		background-position: center;
		padding: $bufferSpaceL 0;
		&:after {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(0deg, rgba($cSlateGrey,0.75) 0%, rgba($cSlateGrey,0) 100%);
		}
	}
	&--clip {
		padding-bottom: $bufferSpaceL*2;
	}
	&--article {
		padding-bottom: 0;
		margin-bottom: $bufferSpaceM;
	}
	&--person {
		.hero__aside {
			margin-bottom: $bufferSpaceS;
		}
	}
	&--campaign {
		background-color: $cTealDark;
		padding: $bufferSpaceL 0 $bufferSpaceM;
		&:after {
			content: "";
			position: absolute;
			display: block;
			bottom: -40px;
			left: 0;
			background-image: url(/assets/img/svg/footer-brush-teal-dark.svg);
			background-size: contain;
			background-repeat: repeat-x;
			background-position: center;
			z-index: -1;
			width: 100%;
			height: 80px;
			transform: rotate(180deg);
		}
		.hero__heading,
		.hero__description,
		.hero__meta {
			color: #fff;
		}
		.hero__description{
			margin-bottom: 0;
		}
	}
	@include respond-to($vpB) {
		&--logo.hero--reverse {
			.hero__main {
				float: right;
				width: 65%;
			}
			.hero__aside {
				float: left;
				width: 25%;
			}
		}
	}
	@include respond-to-max($vpC) {
		&--logo.hero--reverse {
			.hero__aside {
				margin-top: 0;
			}
		}
	}
	@include respond-to-max($vpC) {
		&--logo.hero--reverse.hero--event {
			.hero__main {
				float: left;
				width: auto;
			}
		}
	}
	@include respond-to($vpC) {
		padding: $bufferSpaceL 0 $bufferSpaceM 0;
		&__main {
			width: 55%;
			float: left;
		}
		&__aside {
			width: 35%;
			float: right;
			margin-top: 0;
		}
		&--large {
			padding: $bufferSpaceL*2 0 $bufferSpaceL*1.75 0;
		}
		&--image {
			padding: $bufferSpaceL*2 0 $bufferSpaceL*1.75 0;
		}
		&--clip {
			padding-bottom: $bufferSpaceL*3;
		}
		&--half {
			padding: $bufferSpaceL 0 $bufferSpaceL 0;
		}
		&--video {
			.hero__main {
				float: none;
				margin-bottom: $bufferSpaceM;
			}
		}
		&--article {
			padding-bottom: 0;
			margin-bottom: $bufferSpaceM;
			.hero__main {
				float: none;
				width: 60%;
			}
		}
		&--campaign {
			.hero__main {
				width: 55%;
				position: relative;
			}
			.hero__aside {
				width: 35%;
			}
		}
		.hero--half & {
			&__main {
				width: 50%;
				position: relative;
				transform: translateY(50%);
				top: -50%;
			}
			&__aside {
				width: 45%;
			}
		}
		&--reverse {
			.hero__main {
				float: right;
				width: 65%;
			}
			.hero__aside {
				float: left;
				width: 25%;
			}
		}
		&--person {
			.hero__main {
				float: right;
				width: 75%;
			}
			.hero__aside {
				float: left;
				width: 20%;
				margin-right: 5%;
				margin-bottom: 0;
			}
		}
	}
}

.hero__highlight {
	position: relative;
	z-index: 2;
	background-color: $cMustard;
	padding: $bufferSpaceS*1.5;
	border-radius: $componentRadius;
	@extend %shadowStraightLargeDark;
	.hero__subheading {
		@extend .heading;
		font-size: rem($typeL);
		line-height: 1.3;
	}
	.hero__excerpt {
		font-size: rem($typeDefault);
		margin-bottom: 1em;
	}
	&--alt {
		background-color: $cJade;
	}
	&--light {
    	background-color: #fff;
    }
}


.hero__flag {
	font-size: rem($typeS);
	background-color: $cJade;
	padding: 0.5em 1em;
	display: inline-block;
	margin-bottom: $bufferSpaceS;
}

.hero__meta {
	position: relative;
	font-size: rem($typeDefault);
	list-style: none;
	margin-bottom: $bufferSpaceS;
	li {
		svg {
			width: 17px;
			height: 17px;
			vertical-align: text-top;
			margin-right: 10px;
			display: inline-block;
			fill: $cTealMedium;
		}
	}
	.hero--image & {
		li {
			color: $cCanvas;
			text-shadow: 1px 1px 5px $cHeading;
			svg {
				fill: $cCanvas;
			}
		}
	}
	.hero--campaign & {
		li {
			color: #fff;
			text-shadow: 1px 1px 5px $cHeading;
			svg {
				fill: #fff;
			}
		}
	}
}

.hero__heading {
	@extend .heading;
	font-size: rem($typeXXL);
	line-height: 1;
	margin-bottom: $bufferSpaceS;
	.hero--image & {
		color: $cCanvas;
		text-shadow: 1px 1px 5px $cHeading;
	}
	&--image {
		max-width: 500px;
		img {
			max-width: 100%;
		}
	}
	b {
		font-size: $scaleRoot*6.4px;
		color: $cMustard;
	}
	&--hidden {
		@include a11yhide;
	}
}

.hero__subheading {
	font-size: rem($typeM);
	margin-bottom: $bufferSpaceS;
}

.hero__lead {
	font-size: rem($typeM);
	line-height: 1.4;
	margin-top: $bufferSpaceM;
	.hero--image & {
		color: $cCanvas;
		text-shadow: 1px 1px 5px $cHeading;
	}
}

.hero__description,
.hero__strapline {
	font-size: rem($typeDefault);
	margin-bottom: $bufferSpaceS;
	max-width: 80ch;
	.hero--image & {
		color: $cCanvas;
		text-shadow: 1px 1px 3px $cHeading;
		a {
			color: #fff;
			border-bottom: none;
			text-decoration: underline;
		}
	}
}

.hero__strapline {
	font-size: rem($typeLLL);
}

.hero__video {
	display: none;
	@include respond-to($vpC) {
		z-index: 0;
		left: 0;
		right: 0;
		top: 0;
		height: 100%;
		width: 100%;
		-o-object-fit: cover;
		object-fit: cover;
		position: absolute;
		display: block;
	}
}

.hero__media {
	img {
		@extend .max-width;
		@extend %shadowStraightLarge;
	}
	&--cover {
		display: none;
		@include respond-to($vpC) {
			position: absolute;
			display: block;
			right: $bufferSpaceS;
			top: -50px;
			a {
				display: block;
				border-bottom: 0;
			}
			img {
				width: auto;
				max-height: 450px;
				float: right;
			}
		}
	}
	&--video {
		position: relative;
		@include ratio(16 9);
		iframe,
		video {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&--logo {
		background-color: $cCanvas;
		border-radius: $componentRadius;
		padding: $bufferSpaceS;
		@extend %shadowStraightLargeDark;
		position: relative;
		z-index: 2;
		img {
			margin-left: auto;
			margin-right: auto;
			box-shadow: none;
			// max-width: 250px;
		}
		@include respond-to($vpE) {
			padding: $bufferSpaceM;
		}
	}
	&--feature {
		position: relative;
		z-index: 5;
		transform: translateY($bufferSpaceM);
		background-size: cover;
		background-position: center;
		@include ratio(16 9);
		iframe,
		video {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.hero__credit {
	position: absolute;
	right: 0;
	bottom: 0;
	z-index: 10;
	font-size: rem($typeS);
	color:$cCanvas;
	background-color: rgba($cSlateGrey, 0.75);
	padding: 3px 6px;
	a {
		text-decoration: underline;
		border-bottom: none;
		color: $cCanvas;
	}
	.hero--clip & {
		@include respond-to-max($vpF + 400px) {
			bottom: auto;
			top: $bufferSpaceM;
		}
	}
}


.hero--event {
	@include respond-to-max($vpC) {
		.hero__aside {
			display: none;
		}
		.hero__main {
			float: left;
			width: auto;
		}
	}
	.hero__meta {
		@include respond-to($vpC) {
			// display: none;
		}
	}
}