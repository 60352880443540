@use 'sass:math';

.list-duo {
    @include pie-clearfix;
    list-style: none;
    li {
        @include pie-clearfix;
        padding-bottom: math.div($bufferSpaceS,1.5);
        margin-bottom: math.div($bufferSpaceS,1.5);
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    &--alt {
        background-color: $cCanvas;
        padding: $bufferSpaceS;
        border-radius: $componentRadius;
    }
}

a.list-duo {
    display: block;
    border-bottom: none;
    transition: all, ease-in-out, 0.3s;
    @include link-active-styles {
        @extend %shadowStraightLarge;
        border-bottom: none;
    }
}

.list-data {
    list-style: none;
    li {
        border-bottom: 1px solid $cBorder;
        padding-bottom: math.div($bufferSpaceS,1.5);
        margin-bottom: math.div($bufferSpaceS,1.5);
        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.list-duo__media {
    width: 30%;
    float: left;
    margin-right: 5%;
    img {
        @extend .max-width;
    }
    a {
        border-bottom: 0;
    }
    .list-duo--alt & {
        width: 40%;
    }
}

.list-duo__main {
    width: 65%;
    float: right;
    .list-duo--alt & {
        width: 55%;
    }
}

.list__heading {
    @extend .heading;
    font-size: rem($typeM);
    line-height: 1;
    margin-bottom: 5px;
    a {
        border-bottom: none;
        color: $cTealMedium;
        transition: all, ease-in-out, 0.3s;
        @include link-active-styles {
            color: $cHeading;
        }
    }
    &--large {
        font-size: rem($typeL);
    }
    .list-search & {
        font-size: rem($typeXL);
        margin: $bufferSpaceS 0;
    }
}

.list__excerpt {
    font-size: rem($typeDefault);
}

.list__meta {
    font-size: rem($typeDefault);
    svg {
        width: 17px;
        height: 17px;
        vertical-align: text-top;
        margin-right: 10px;
        display: inline-block;
        fill: $cTealMedium;
    }
}

.list__flag {
    font-size: rem($typeS);
    background-color: $cCream;
    padding: 0 10px;
    display: inline-block;
    margin-top: math.div($bufferSpaceS,2);
    .list-duo--coalition & {
        background-color: $cTurquoise;
    }
    .list-duo--partnership & {
        background-color: $cMustard;
    }
    .list-search & {
        background-color: $cCanvasMedium;
    }
}


.list-search {
    position: relative;
    margin-bottom: $bufferSpaceM;
    padding-bottom: $bufferSpaceM;
    &:after {
        content: "";
        background-image: url(/assets/img/svg/divider.svg);
        background-size: contain;
        background-repeat: repeat-x;
        background-position: center;
        display: block;
        width: 100%;
        height: 20px;
        position: absolute;
        bottom: -10px;
        left: 0;
    }
}

.list-search__highlight {
    background-color: $cMustard;
}

[data-js-filtered-list] {
    html.no-js & {
        display: none;
    }
}

[data-no-js-filtered-list] {
    html.js & {
        display: none;
    }
}
