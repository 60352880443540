@use 'sass:math';

.section {
    position: relative;
    padding-top: math.div($bufferSpaceL,1.5);
    padding-bottom: math.div($bufferSpaceL,1.5);
    .side-bar__main-wide &:first-of-type{
    	padding-top: 0;
    }
    &--minor {
    	padding-top: $bufferSpaceM;
    }
    .hero--campaign + & {
    	margin-top: $bufferSpaceS;
    	@include respond-to($vpC) {
    		margin-top: $bufferSpaceM;
    	}
    	@include respond-to($vpD) {
    		margin-top: $bufferSpaceL;
    	}
    }
    &--divide {
        margin-top: math.div($bufferSpaceL,1.5);
        .row {
            &:before {
                content: "";
                background-image: url(/assets/img/svg/divider.svg);
                background-size: contain;
                background-repeat: repeat-x;
                background-position: center;
                display: block;
                width: 100%;
                height: 20px;
                position: absolute;
                top: -$bufferSpaceL*0.66666667;
                left: 0;
            }
        }
    }
    &--clip {
        z-index: 1;
        padding-top: 0;
        transform: translateY(-$bufferSpaceM);
        margin-bottom: -$bufferSpaceM;
        padding-bottom: 0;
        @include respond-to($vpB) {
            transform: translateY(-$bufferSpaceL);
            margin-bottom: -$bufferSpaceL;
        }
        @include respond-to($vpC) {
            transform: translateY(-$bufferSpaceL*2);
            margin-bottom: -$bufferSpaceL*2;
            padding-bottom: 0;
        }
    }
    &--highlight {
        background-color: $cCanvas;
        margin-top: $bufferSpaceL*1.5;
        margin-bottom: math.div($bufferSpaceL,1.5);
        padding-bottom: math.div($bufferSpaceL,1.5);
        &:before {
            content: "";
            position: absolute;
            display: block;
            top: -40px;
            left: 0;
            background-image: url(/assets/img/svg/footer-brush.svg);
            background-size: contain;
            background-repeat: repeat-x;
            background-position: center;
            z-index: -1;
            width: 100%;
            height: 80px;
        }
        &:after {
            content: "";
            position: absolute;
            display: block;
            bottom: -40px;
            left: 0;
            background-image: url(/assets/img/svg/footer-brush.svg);
            background-size: contain;
            background-repeat: repeat-x;
            background-position: center;
            z-index: -1;
            width: 100%;
            height: 80px;
            transform: rotate(180deg);
        }
    }
    &--lowlight {
        background-color: $cCanvasMedium;
        // background-color: #359eb2; // updated to match filtered footer-brush.svg
        // margin-top: $bufferSpaceL*1.5;
        margin-bottom: math.div($bufferSpaceL,1.5);
        padding-bottom: math.div($bufferSpaceL,1.5);
        &:after {
            content: "";
            position: absolute;
            display: block;
            bottom: -40px;
            left: 0;
            background-image: url(/assets/img/svg/footer-brush-canvas-medium.svg);
            background-size: contain;
            background-repeat: repeat-x;
            background-position: center;
            // Filter to $ccTealLight
            // filter: invert(50%) sepia(91%) saturate(357%) hue-rotate(143deg) brightness(84%) contrast(84%);
            z-index: -1;
            width: 100%;
            height: 80px;
            transform: rotate(180deg);
        }
        .section__subheading {
        	// color: #fff;
        }
        .hero--campaign + & {
	    	margin-top: 0;
	    }
    }


    &--centered {
        .typebase {
            font-size: 1.8rem;
            margin: 0 auto;
            max-width: 75ch;
        }
    }
}

.section__header {
    margin-bottom: $bufferSpaceM;
    scroll-margin-top: 25px;
    .section__subheading {
        margin-top: $bufferSpaceS;
    }
}

// animate number prototype
// @property --num {
//     syntax: "<integer>";
//     initial-value: 0;
//     inherits: false;
// }

@keyframes counter {
    from {
      --num: 0;
    }
    to {
      --num: 1500;
    }
}

.section__heading {
    @extend .heading;
    font-size: rem($typeXL);
    line-height: 1;
    span {
        color: $cMustard;
        &.integer-text {
            color: inherit;
        }
    }
    i {
        font-style: normal;
        color: $cMustard;
    }
    b {
        font-weight: normal;
        color: $cTurquoise;
    }
    &--background-color {
        i {
            color: $cHeading;
            background-color: $cMustard;
        }
        b {
            color: $cHeading;
            background-color: $cTurquoise;
        }
    }
    &--center {
        text-align: center;
    }
    @include respond-to($vpC) {
        font-size: rem($typeXXL);
    }
}

.section__subheading {
    @extend .heading;
    font-size: rem($typeXL);
    line-height: 1;
    span {
        margin-bottom: $bufferSpaceS;
        display: inline-block;
        margin-right: 1em;
    }
}

.section__excerpt {
    font-size: rem($typeDefault);
    margin-top: $bufferSpaceS;
    max-width: 800px;
}
