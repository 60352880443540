.panel {
    position: relative;
    background-color: $cCanvas;
    padding: $bufferSpaceS*1.5;
    padding-bottom: $bufferSpaceM*1.5;
    border-bottom: 0;
    transition: all, ease-in-out, 0.3s;
    transform: scale(1);
    display: block;
    @include link-active-styles {
        border-bottom: 0;
        @extend %shadowStraightLargeMedium;
        border-radius: $componentRadius;
        .panel__action {
            bottom: $bufferSpaceS*2;
        }
    }
    @include respond-to($vpB) {
        padding: $bufferSpaceM;
        padding-bottom: $bufferSpaceL*1.5;
        @include link-active-styles {
            transform: scale(1.1);
            z-index: 2;
            .panel__action {
                bottom: $bufferSpaceM*1.5;
            }
        }
    }
    &--active {
        @extend %shadowStraightLargeMedium;
        border-radius: $componentRadius;
        padding-bottom: $bufferSpaceM;
        @include respond-to($vpB) {
            transform: scale(1);
        }
    }
    &--v1 {
        background-color: $cTheoryOrange;
    }
    &--v2 {
        background-color: $cTheoryYellow;
    }
    &--v3 {
        background-color: $cTheoryBlue;
    }
    &--v4 {
        background-color: $cTheoryBlueDark;
        .panel__heading, .panel__description {
            color: $cCanvas;
        }
        .panel__action {
            color: $cCanvas;
            border-color: $cCanvas;
        }
    }
    &--v5 {
        background-color: $cCerise;
        .panel__heading, .panel__description {
            color: $cCanvas;
        }
        .panel__action {
            color: $cCanvas;
            border-color: $cCanvas;
        }
    }
    &--v6 {
        background-color: $cMustard;
    }
    &--v7 {
        background-color: $cJade;
    }
}

.panel__heading {
    @extend .heading;
    line-height: 1;
    font-size: rem($typeL);
    margin-bottom: $bufferSpaceS;
}

.panel__description {
    font-size: rem($typeDefault);
    .hero__aside & {
        margin-bottom: $bufferSpaceS;
    }
}

.panel__action {
    @extend .heading;
    position: absolute;
    bottom: $bufferSpaceS*1.5;
    left: $bufferSpaceS*1.5;
    font-size: rem($typeDefault);
    border: 2px solid $cHeading;
    padding: 0 1em;
    border-radius: 1.5em;
    transition: all, ease-in-out, 0.3s;
    svg{
        width: 12px;
        height: 12px;
        transform: rotate(-90deg);
    }
    @include respond-to($vpB) {
        bottom: $bufferSpaceM;
        left: $bufferSpaceM;
    }
}
