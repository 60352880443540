.pagination {
    @extend %pie-clearfix;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: $bufferSpaceL;
    .pagination__item-link {
        font-size: rem($typeS);
    }
}

.pagination__item {
    font-size: rem($typeS);
    display: inline-block;
    display: none;
    @include respond-to($vpC) {
        display: block;
    }
}

.pagination__item--prev {
    margin-right: 1.5em;
    display: block;
}
.pagination__item--next {
    margin-left: 1.5em;
    display: block;
}

.pagination__item-link {
    display: block;
    padding: 0.75em 1em;
}

a.pagination__item-link {
    transition: all .3s ease-in-out;
    border-bottom: 2px solid transparent;
    &:hover {
        background-color: $cCanvas;
        border-bottom: 2px solid $cAction;
    }
}

.pagination__item-link--active {
    background-color: $cCanvas;
    border-bottom: 2px solid $cAction;
}

.pagination__item-prev-link,
.pagination__item-next-link {
    background-color: $cAction;
    padding: 0.75em 1.25em;
    transition: all 0.3s ease-in-out;
    border-radius: $btnRadius;
    &:hover {
        background-color: $cActionRespond;
    }
}
